import React, { useState, useContext, useEffect } from 'react';
import { services } from '../services/api'
import Button from '@mui/material/Button';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Grid from '@mui/material/Grid';
import { Box, TextareaAutosize, FormControl, Tooltip } from '@mui/material';
import IconUI from '../componets/ui/icon_ui';
import { AiOutlineUserAdd, AiOutlineEdit } from 'react-icons/ai'
import { FcCancel } from 'react-icons/fc'
import MUIDataTable from "mui-datatables";
import { NotificacionContext } from '../context/notificacion_context';
import { styled } from '@mui/material/styles';
import Modal from '@mui/material/Modal';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import dayjs from 'dayjs';
import { onlyLetterNumber } from '../utils/inputsRules';
import LoaderComponent from './LoaderComponent';


const options = {
  selectableRows: 'none',
  tableBodyHeight: 'auto',
  print: false,
  viewColumns: false,
  filter: false,
  textLabels: {
    body: {
      noMatch: "Lo sentimos, no hay coincidencias en la busqueda",
      toolTip: "Clasificar",
      columnHeaderTooltip: column => `Clasificar por ${column.label}`
    },
    pagination: {
      next: "Siguiente",
      previous: "Anterior",
      rowsPerPage: "Filas por página:",
      displayRows: "de",
    },
    toolbar: {
      search: "Buscar",
      downloadCsv: "Descargar CSV",
      print: "Imprimir",
      viewColumns: "Ver columnas",
      filterTable: "Filtrar tabla",
    },
    filter: {
      all: "Todo",
      title: "Filtros",
      reset: "Restaurar",
    },
    viewColumns:false,
    selectedRows: {
      text: "columna(s) selecciona(as)",
      delete: "Eliminar",
      deleteAria: "Eliminar filas seleccionadas",
    },
  },
  responsive: 'standard',
  downloadOptions: {
    filename: 'tablaUsuarios.csv',
    filterOptions: {
      useDisplayedColumnsOnly: false,
      useDisplayedRowsOnly: false,
    }
  },

};


const styleModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  mb: 2,
  display: "flex",
  flexDirection: "column",
  height: 550,
  overflow: "hidden",
  overflowY: "scroll",
};

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText('#005cbf'),
  backgroundColor: '#4A001F',
  '&:hover': {
    backgroundColor: '#4A001F',
  },
}));

const Noticias = () => {

  const { showNotification } = useContext(NotificacionContext)

  const [loader, setLoader] = useState(false)
  const [update, setUpdate] = useState({
    imagenName: null,
    nombre: " ",
    img: null,
    descripcion: " ",
    data: null,
    open: false,
    nombreError: false,
    imgedit: null,
    type: 'insert',
    id: null,
  });

  const [stateError , setStateError] = useState({
    nombreError: false,
    imgError: false,
    descripcionError: false,
  });


  const guardarimagen = (e) => {
    const img = e.target.files;
    const foto = img[0];

    const URLImagenEdit = URL.createObjectURL(foto);
    setUpdate({ ...update, img: foto, imagenName: foto.name, imgedit: URLImagenEdit });

  }

  const validar= () =>{

    let nombre = update.nombre !== '' && update.nombre !== null;
    let img = update.img !== null;
    let descripcion = update.descripcion !== '' && update.descripcion !== null;

    setStateError({
      nombreError: !nombre,
      imgError: !img,
      descripcionError: !descripcion,
    })

    return nombre && img && descripcion;
  }

  const subirNoticia = (e) => {

    if(validar()){
      setLoader(true)
    try {
      const body = new FormData();
      body.append("titulo", update.nombre);
      body.append("foto", update.img);
      body.append("descripcion", update.descripcion);

      services('POST', `usuarios/crearnoticias`, body, null).then((data) => {
        if (data?.status === 500) {
          showNotification('error', 'Algo salio mal', 'Intente mas tarde', null, 4);
        } else {
          if (data?.data.error) {
            showNotification('error', 'Algo salio mal', data?.data.error, null, 4);
          } else {
            const apiUpdate = async () =>{
              let noticiasUpdated = await services('GET','usuarios/listarnoticias',null);
              setUpdate({ ...update, data: noticiasUpdated.data, nombre:" ", descripcion: " ",  imagenName: "", img: null, imgedit: null, id: "",  open: false, });
              showNotification('success', 'Noticia Creada', data?.data.mensaje, null, 4);
              setLoader(false)

            }
            apiUpdate();
          }
        }
      })
    } catch (error) {

      showNotification('error', 'Algo salio mal', 'Intente mas tarde', null, 4);
    }

  }else{showNotification("warning", "Campos requeridos!", "Por favor, llena todos los campos requeridos", null, 4);}

  }

  const updateNoticia = () => {

    try {

      const body = new FormData();
      body.append("titulo", update.nombre);
      body.append("foto", update.img ? update.img : '');
      body.append("descripcion", update.descripcion);
      const URLImagenEdit = update.img ? URL.createObjectURL(update.img) : false;

      services('POST', `usuarios/editarnoticias/${update.id}`, body, null).then((data) => {
        handleClose();
        if (data?.status === 500) {
          showNotification('error', 'Algo salio mal', 'Intente mas tarde', null, 4);
        } else {
          if (data?.data.error) {
            showNotification('error', 'Algo salio mal', data?.data.error, null, 4);
          } else {
            setUpdate({
              ...update,
              data: update.data.map(item => {
                return item.id === update.id ? { titulo: update.nombre, rutafoto: URLImagenEdit ? URLImagenEdit : item.rutafoto, descripcion: update.descripcion, fecha: item.fecha, id: item.id } : item
              }),
              open: false,
              nombre: null,
              descripcion: null,
              imagenName: null,
              img: null,
              imgedit: null,
              id: null
            })
            showNotification('success', 'Noticia Actualizada', data?.data.mensaje, null, 4);
          }
        }
      });



    } catch (error) {
    }

  }


  const handleClose = () => {
    setUpdate({
      ...update,
      open: false,
      nombre: "",
      descripcion: "",
      imagenName: "",
      img: null,
      imgedit: null,
      id: ""
    });
  }

  const columns = [
    {
      name: "titulo",
      label: "Titulo",
      align: "center",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex, i) => {
          let nombre = update.data[dataIndex].titulo;
          return (<div style={{ height: '60px' }} className={i % 2 === 0 ? "bg-rosaClaro d-flex align-items-center justify-content-center" : "d-flex align-items-center justify-content-center"}>{nombre}</div>)
        }
      }
    },
    {
      name: "descripcion",
      label: "Descripción",
      align: "center",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex, i) => {
          let Descripcion = update.data[dataIndex].descripcion;
          return (<div style={{ height: '60px' }} className={i % 2 === 0 ? "bg-rosaClaro d-flex align-items-center justify-content-center" : "d-flex align-items-center justify-content-center"}>{Descripcion}</div>)
        }
      }
    },
    {
      name: "rutafoto",
      label: "Imagen",
      align: "center",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex, i) => {
          let imagen = update.data[dataIndex].rutafoto;
          return (
            <div style={{ height: '60px' }} className={i % 2 === 0 ? "bg-rosaClaro d-flex align-items-center justify-content-center" : "d-flex align-items-center justify-content-center"}>
              <img className="img--news" style={{ height: '60px', width: "70px" }} src={imagen} alt={''}></img>
            </div>
          )
        }
      }
    },
    {
      name: "creado",
      label: "Creado",
      align: "center",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex, i) => {
          let creado = update.data[dataIndex].fecha;
          let currentDay = dayjs(creado).format("DD-MM-YYYY")
          return (<div style={{ width: '100px' }} className={i % 2 === 0 ? "bg-rosaClaro d-flex align-items-center justify-content-center" : "d-flex align-items-center justify-content-center"}>{currentDay}</div>)
        }
      }
    },
    {
      name: "acciones",
      label: "Acciones",
      align: "center",
      options: {
        filter: false,
        sort: false,
        download: false,
        customBodyRenderLite: (dataIndex, i) => (
          <>
            <div style={{ height: '60px' }} className={i % 2 === 0 ? "w-100 d-flex align-items-center justify-content-center bg-rosaClaro " : "w-100 d-flex justify-content-center align-items-center "}>
              <Tooltip title="Editar Noticia">
                <div style={{ height: 30, width: 30, zIndex: 0, }} className='cta cta--primary cta--icon icon_btn' onClick={
                  () => {

                    editNews(update.data[dataIndex]);
                  }}>
                  <IconUI size={'20px'} color={'black'}><AiOutlineEdit /></IconUI>

                </div>
              </Tooltip>
              <Tooltip title="Eliminar Noticia">
              <div style={{ height: 30, width: 30, zIndex: 0, marginLeft: 5 }} className='cta cta--warning cta--icon icon_btn' onClick={
                () => {
                  showNotification("option", "Estás seguro de realizar esta acción?", "Al dar click en Aceptar se eliminara esta noticia", () => {
                    eliminarnoticia(update.data[dataIndex].id);
                  }, null)
                }
              }>
                <IconUI size={'20px'} color={'black'}><FcCancel /></IconUI>

              </div>
              </Tooltip>
            </div>
          </>
        )
      }
    },
  ];



  const eliminarnoticia = (id) => {
    try {
      services('POST', `usuarios/eliminarnoticias/${id}`, null, null).then((data) => {
        if (data?.status === 500) {
          showNotification('error', 'Algo salio mal', 'Intente mas tarde', null, 4);
        } else {
          if (data?.data.error) {
            showNotification('error', 'Algo salio mal', data?.data.error, null, 4);
          } else {
            showNotification('success', 'Noticia Eliminada', data?.data.mensaje, null, 4);
            const apiUpdateDelete = async () =>{
              let noticiasUpdated = await services('GET','usuarios/listarnoticias',null);
              setUpdate({ ...update, data: noticiasUpdated.data });
            }
            apiUpdateDelete();
          }
        }
      });
    } catch (error) {
    }

  }



  const editNews = (input) => {

    const { titulo, descripcion, rutafoto, id } = input;
    setUpdate({ ...update, open: true, type: 'edit', nombre: titulo, descripcion: descripcion, imgedit: rutafoto, id: id })

  }


  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      services('GET', 'usuarios/listarnoticias', null).then((info) => {
        setUpdate({ ...update, data: info.data });

      });
    }

    return () => {
      isMounted = false
    }
    // eslint-disable-next-line
  }, [])


  const handleChangedesc = (e) => {
    setUpdate({ ...update, descripcion: e.target.value });
  }

  const handleChangetitulo = (e) => {

    setUpdate({ ...update, nombre: e.target.value });
  }

  const openmodal = () => {
    setUpdate({ ...update, open: true, type: 'insert' })
  }


  return (
    <>
    {loader &&
      <LoaderComponent />
    }
      {   update.data  ? 
      <div style={ update.data.length > 3 ? {} : {height:'calc(100vh)'}}>
      <div className='container' >
        <Grid sx={{ marginTop: 3 }} container spacing={2}>
          <Grid align="center" item xs={12} sm={6} md={6}>
          </Grid>
          <Grid align="right" item xs={12} sm={6} md={6}>
            <div style={{ width: 200 }} className="cta cta--guinda mt-3" onClick={
              () => {
                openmodal();
              }
            }><div className="me-2"><IconUI size={'20px'} color={'white'}><AiOutlineUserAdd /></IconUI></div>Nueva Noticia</div>
          </Grid>
        </Grid>
      </div>

      <Box sx={{ flexGrow: 1 }}>
        <Grid className='container mt-5 mb-5'>
          <Grid item xs={8}>
            <MUIDataTable
              style={{ Overflow: 'hidden' }}
              title={"Noticias"}
              data={update.data}
              columns={columns}
              options={options}
            />
          </Grid>
        </Grid>
      </Box>



      <Modal
        open={update.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal}>

        <Grid item xs={12} sm={12} md={12}>
            <h4> {update.type === 'insert' ? 'Agregar Noticia': 'Editar Noticia'}</h4>
            <hr></hr>
        </Grid>
          <Grid container spacing={2} sx={{ justifyContent: 'center', alignItems: 'center' }}>
            <Grid item xs={12} sm={12} md={12}>
              <InputLabel id="label-titulo" style={{ paddingBottom: '5%' }} className="mx-2">Titulo</InputLabel>
              <TextField
                fullWidth
                error={stateError.nombreError}
                id="titulo"
                variant="standard"
                type='text'
                value={update.nombre}
                onChange={handleChangetitulo}
              />
            </Grid>
          </Grid>
          {update.type === 'insert' ?
            <>
              
              <Grid sx={{ marginTop: 1, marginBottom: 2 }} container justifyContent="space-between" spacing={0}>
                  <Grid style={{ width: '100%' }} item xs={12} sm={12} md={12}>
                  <InputLabel sx={{ marginBottom: 4 }} align="left" >Descripción</InputLabel>
                  <FormControl style={{ width: '100%' }}>
                      <TextareaAutosize
                      placeholder="Ingresa una Descripcion"
                      id="desc"
                      error={stateError.descripcionError}
                      value={update.descripcion}
                      onChange={handleChangedesc}
                      style={{ width: '100%', height: 100, paddingTop: 15, paddingLeft: 15, paddingRight: 15, paddingBottom: 15 }}
                      />
                  </FormControl>
                  </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ justifyContent: 'center', alignItems: 'center' }}>
                <Grid item xs={10} sm={10} md={10}>

                  <TextField
                    style={{ paddingTop: '5%' }}
                    variant="standard"
                    fullWidth
                    hiddenLabel
                    error={stateError.imgError}
                    disabled
                    id="filled-hidden-label-normal"
                    value={update.imagenName ? update.imagenName : "Imagen"}
                    size="small"
                  />

                </Grid>
                <Grid item xs={2} sm={2} md={2}>
                  <Button variant="contained" component="label">
                    <FileUploadIcon />
                    <input type="file" hidden onChange={guardarimagen} accept='.png , .jpg' />
                  </Button>
                </Grid>
              </Grid>
            </>
            :
            <>
              <Grid container spacing={2} sx={{ justifyContent: 'space-between', alignItems: 'center', marginTop: '3%' }}>
                <Grid item xs={4}>
                  <InputLabel id="label-desc" style={{ paddingBottom: '10%' }} className="mx-2">Descripción</InputLabel>
                  <TextField
                    fullWidth
                    id="desc"
                    variant="standard"
                    type='text'
                    multiline
                    rows={4}
                    rowsMax={10}
                    onKeyPress={(event) => {onlyLetterNumber(event, 250);}}
                    value={update.descripcion}
                    onChange={handleChangedesc}
                  />
                </Grid>
                <Grid item xs={4}>
                  <img className="img--news" style={{ width: '100%' }} src={update.imgedit} alt={''}></img>
                </Grid>
                <Grid item xs={2}>
                  <Button variant="contained" component="label" sx={{ marginBottom: '25%' }}>
                    <ChangeCircleIcon />

                    <input type="file" hidden onChange={guardarimagen} accept='.png , .jpg' />
                  </Button>
                </Grid>
              </Grid>
            </>}

          <Grid container spacing={2} sx={{ justifyContent: 'space-between', alignItems: 'center', paddingTop: '4%' }}>
            <Grid item xs="auto">
              <ColorButton variant="outlined"
                type='button'
                endIcon={
                  <CloseIcon />
                }
                onClick={handleClose}>
                Cancelar
              </ColorButton>
            </Grid>
            <Grid item xs={5}></Grid>
            {update.type === 'insert' ?
              <Grid item xs="auto">
                <ColorButton variant="outlined"
                  type='button'
                  endIcon={
                    <SaveIcon />
                  }
                  onClick={() => subirNoticia()}>
                  Guardar
                </ColorButton>
              </Grid>
              :
              <Grid item xs="auto">
                <ColorButton variant="outlined"
                  type='button'
                  endIcon={
                    <SaveIcon />
                  }
                  onClick={() => updateNoticia()}>
                  Actualizar
                </ColorButton>
              </Grid>
            }
          </Grid>
        </Box>
      </Modal>
      </div>
      : <div style={{height:'calc(100vh - 80px)'}}><span class="loader"></span></div>}
    </>
  );

}



export default Noticias;