import React, { useState, useContext, useEffect } from 'react'
import DataTable from './DataTableComponent';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Modal from '@mui/material/Modal';
import TextField from "@mui/material/TextField";
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { services } from '../services/api'
import Button from '@mui/material/Button';
import { NotificacionContext } from '../context/notificacion_context';
import IconUI from './ui/icon_ui';
import SchoolIcon from '@mui/icons-material/School';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { toMexico, onlyNumber, maxLengthLimitInput } from '../../src/utils/inputsRules';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import FormHelperText from '@mui/material/FormHelperText';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { Divider, Tooltip } from '@mui/material';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import dayjs from 'dayjs';
import LoaderComponent from './LoaderComponent';





const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText('#005cbf'),
    backgroundColor: '#4A001F',
    '&:hover': {
        backgroundColor: '#4A001F',
    },
}));


const Tpp = () => {

    const [state, setState] = useState({
        open: false,
        openDetalle: false,
        periodos: [],
        profesores: [],
        talleres: [],
        talleresDisciplina: [],
        listarTPP: null,
        grupo: '',
        capacidad: '',
        precio: '',
        fecha_fin: '',
        fecha_inicio: '',
        salon: '',
        profesor: '',
        taller: '',
        periodo: ''

    });

    const [loader, setLoader ] = useState(false)
    const handleChangePeriodo = (event) => {
        setState({ ...state, periodo: event.target.value });
    };

    const handleChangeTaller = (event) => {
        setState({ ...state, taller: event.target.value });
    };

    const handleChangeProfesor = (event) => {
        setState({ ...state, profesor: event.target.value });
    };

    const handleChangeGrupo = (event) => {
        setState({ ...state, grupo: event.target.value });
    };
    const handleChangeCapacidad = (event) => {
        setState({ ...state, capacidad: event.target.value });
    };
    const handleChangePrecio = (event) => {
        setState({ ...state, precio: event.target.value });
    };

    const handleChangeFechaInicio = (event) => {
        setState({ ...state, fecha_inicio: event.target.value });
    };
    const handleChangeFechaFin = (event) => {
        setState({ ...state, fecha_fin: event.target.value });
    };


    const handleChangeSalon = (event) => {
        setState({ ...state, salon: event.target.value });
    }



    const { showNotification } = useContext(NotificacionContext)

    const openmodal = () => {
        setState({ ...state, open: true });
    }
    const handleClose = () => {
        setState({ ...state, open: false });
    }

    const openmodalDetalle = (talleres) => {
        setState({ ...state, openDetalle: true, talleresDisciplina: talleres });
    }
    const handleCloseDetalle = () => {
        setState({ ...state, openDetalle: false });
    }


    const subirTaller = async () => {

        if (validar()) {
            setLoader(true)
            const dataTPP = await services("POST", `usuarios/crearperiodostalleres?id_profesor=${state.profesor}&id_taller=${state.taller}&id_periodo=${state.periodo}&grupo=${state.grupo}&precio=${state.precio}&capacidad=${state.capacidad}&fechaincrpcioninicio=${state.fecha_inicio}&fechaincrpcionfin=${state.fecha_fin}&salon=${state.salon}`, null)
            if (dataTPP.status === 200) {
                getListaTPP();
                handleClose()
                showNotification('success', 'Taller vinculado', '', null, 4);
                setLoader(false)
            } else {
                showNotification('error', 'Ups.. algo salio mal, intenta mas tarde', '', null,4);
                setLoader(false)
            }

        }



    }

    const getListaTPP = async () => {
        const dataPeriodos = await services("GET", 'usuarios/listarperiodos', null)
        const dataProfesores = await services("POST", 'usuarios/listarusuarios?tipo=Profesor', null)
        const dataTalleres = await services("GET", 'usuarios/listartalleres', null)
        const dataListarTPP = await services("GET", 'usuarios/listarperiodostallers')

        setState({
            ...state,
            periodos: dataPeriodos.data,
            profesores: dataProfesores.data,
            talleres: dataTalleres.data,
            listarTPP: dataListarTPP.data,
            openDetalle: false,
            open: false,
            grupo: '',
            capacidad: '',
            precio: '',
            fecha_fin: '',
            fecha_inicio: '',
            salon: '',
            error: '',
        })
    }




    useEffect(() => {
        let isMounted = true
        if (isMounted) {
            getListaTPP()
        }

        return () => {
            isMounted = false
        }
        // eslint-disable-next-line
    }, [])


    const validar = () => {
        let profesor = state.profesor;
        let taller = state.taller;
        let periodo = state.periodo;
        let grupo = state.grupo;
        let salon = state.salon;
        let capacidad = state.capacidad;
        let precio = state.precio;
        let fecha_inicio = state.fecha_inicio;
        let fecha_fin = state.fecha_fin;

        if (!periodo || !taller || !profesor || !grupo || !salon || !capacidad || !precio || !fecha_inicio || !fecha_fin) {
            setState({ ...state, error: 'Todos los campos son requeridos' })
            return false
        } else if (!fecha_inicio || !fecha_fin) {
            if (!fecha_inicio) {
                setState({ ...state, error: 'Selecciona una fecha inicio' })
            } else if (!fecha_fin) {
                setState({ ...state, error: 'Selecciona una fecha final' })
            }
            return false
        } else {
            setState({ ...state, error: '' })
            return true
        }


    }


    const columns = [
        {
            name: "disciplina",
            label: "Disciplina",
            align: "center",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex, i) => {
                    let disciplina = state.listarTPP[dataIndex].disciplina;
                    return (<div style={{ height: '60px', width: '100%' }} className={i % 2 === 0 ? "bg-rosaClaro d-flex align-items-center justify-content-center" : "d-flex align-items-center justify-content-center"}>{disciplina}</div>)
                }
            }
        },
        {
            name: "acciones",
            label: "Acciones",

            options: {
                filter: false,
                sort: false,
                download: false,
                customBodyRenderLite: (dataIndex, i) => (
                    <>
                        <div style={{ height: '60px', width: '100%' }} className={i % 2 === 0 ? "bg-rosaClaro d-flex align-items-center justify-content-center" : "d-flex align-items-center justify-content-center"}>
                            <Tooltip title="Ver Detalles">
                            <div style={{ height: 30, width: 30, zIndex: 0, }} className='cta cta--primary cta--icon icon_btn' onClick={
                                () => {

                                    let talleres = state.listarTPP[dataIndex].talleres;
                                    openmodalDetalle(talleres)
                                }}>
                                <IconUI size={'20px'} color={'black'}><RemoveRedEyeIcon /></IconUI>

                            </div>
                            </Tooltip>
                        </div>
                    </>
                )
            }
        },
    ];
    const [modalClone, setClone] = useState(false);
    const openModalClone = () => {
        setClone(true)
    }
    const handleCloseClone = () => {
        setClone(false);
        setDataClone({  
            periodoI:"",
            periodoF:"",
            fechaI:"",
            fechaF:""
        })
    }

    const [dataClone, setDataClone] = useState({
        periodoI:"",
        periodoF:"",
        fechaI:"",
        fechaF:""

    })
    
    const handleChangeClone = ({target}) =>{
        const {name, value} = target
        setDataClone({...dataClone,[name]:value}) 
    } 

    const getClone = async() =>{
        let periodoI = dataClone.periodoI;
        let periodoF = dataClone.periodoF;
        let fechaI = dataClone.fechaI;
        let fechaF = dataClone.fechaF;

        if(periodoI === "" || periodoF === "" || fechaI === "" || fechaF === ""  ){
            showNotification('error', 'La informacion no puede ir vacía', '', null);
        }else{
            let data = await services("POST", `usuarios/clonar?id_periodoanterior=${dataClone.periodoI}&fecha_inicio=${dataClone.fechaI}&fecha_fin=${dataClone.fechaF}&id_periodonew=${dataClone.periodoF}`)
            
            if (data.status === 200) {
                getListaTPP();
                handleCloseClone()
                showNotification('success', 'Taller(es) Duplicado(s)', '', null);
            } else {
                showNotification('error', 'Ups.. algo salio mal, intenta mas tarde', '', null);
            }
        }
    }    


    return (
        <>
        {loader &&
            <LoaderComponent />
        }
            {state.listarTPP ?

                <div style={state.listarTPP.length > 3 ? {} : { height: 'calc(100vh - 80px)' }}>

                    <div className='container'>
                        <Grid  sx={{ marginTop: 3 }} container spacing={2}>
                            <Grid className='mt-4' align="center" item xs={12} sm={6} md={6}>
                                <div style={{ width: 300 }} className="cta cta--guinda" onClick={
                                    () => {
                                        openModalClone();
                                    }
                                }><div className="me-2"><IconUI size={'20px'} color={'white'}><FileCopyIcon /></IconUI></div>Duplicar</div>

                            </Grid>
                            <Grid className='mt-4' align="center" item xs={12} sm={6} md={6}>
                                <div style={{ width: 300 }} className="cta cta--guinda" onClick={
                                    () => {
                                        openmodal();
                                    }
                                }><div className="me-2"><IconUI size={'20px'} color={'white'}><SchoolIcon /></IconUI></div>Nuevo Taller Profesor Periodo</div>
                            </Grid>
                        </Grid>
                    </div>
                    <DataTable
                        data={state.listarTPP}
                        title={'Control de Talleres Profesores Periodo'}
                        columns={columns}
                        more={{
                            searchPlaceholder:"Buscar por Disciplina"
                        }}
                    />

                    <Modal
                        open={state.open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <div className="boxModals">

                            <Grid item xs={12} sm={12} md={12}>
                                <h4>Agregar TPP</h4>
                                <hr></hr>
                            </Grid>
                            <div className='mb-3'>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Periodo</InputLabel>
                                    <Select
                                        labelId="periodo"
                                        id="periodo"
                                        label="Age"
                                        error={state.error}
                                        onChange={handleChangePeriodo}

                                    >
                                        {state.periodos?.map((item) => {
                                            return (
                                                <MenuItem value={item.id}>{item.fecha_inicio} a {item.fecha_fin} - Estatus: {item.status}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                    <FormHelperText>Selecciona un Periodo Activo</FormHelperText>
                                </FormControl>
                            </div>

                            <div className='mt-4'>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Taller</InputLabel>
                                    <Select
                                        labelId="taller"
                                        id="taller"
                                        label="Age"
                                        error={state.error}
                                        value={state.taller}
                                        helperText='Ingresaun Taller'

                                        onChange={handleChangeTaller}
                                    >
                                        {state.talleres?.map((item) => {
                                            return (
                                                <MenuItem value={item.id}>{item.nombretaller}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                    <FormHelperText>Selecciona un Taller Activo</FormHelperText>
                                </FormControl>
                            </div>
                            <div className='my-4'>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Profesor</InputLabel>
                                    <Select
                                        labelId="profesor"
                                        id="profesor"
                                        label="Age"
                                        error={state.error}
                                        value={state.profesor}
                                        onChange={handleChangeProfesor}
                                    >
                                        {state.profesores?.map((item) => {
                                            return (
                                                <MenuItem value={item.id}>{item.nombre}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                    <FormHelperText>Selecciona un Profesor</FormHelperText>
                                </FormControl>


                            </div>

                            <div className='my-4'>
                                <TextField
                                    label="Grupo"
                                    fullWidth
                                    id="grupo"
                                    value={state.grupo}
                                    error={state.error}
                                    onKeyPress={(e) => { maxLengthLimitInput(e, 25, 'razon_social'); }}
                                    helperText='Ingresa un Grupo'
                                    onChange={handleChangeGrupo}
                                />
                            </div>
                            <div className='my-4'>
                                <TextField
                                    label="Salon"
                                    fullWidth
                                    id="salon"
                                    value={state.salon}
                                    error={state.error}
                                    onKeyPress={(e) => { maxLengthLimitInput(e, 25, 'razon_social'); }}
                                    helperText='Ingresa un Salon'
                                    onChange={handleChangeSalon}
                                />
                            </div>

                            <div className='my-4'>
                                <TextField
                                    label="Capacidad"
                                    fullWidth
                                    id="capacidad"
                                    value={state.capacidad}
                                    error={state.error}
                                    onKeyPress={(e) => { onlyNumber(e); }}
                                    helperText='Ingresa la cantidad de Asistentes al Taller'
                                    onChange={handleChangeCapacidad}
                                />
                            </div>
                            <div className='my-4'>
                                <TextField
                                    label="Precio"
                                    fullWidth
                                    id="Precio"
                                    value={state.precio}
                                    error={state.error}
                                    onKeyPress={(e) => { onlyNumber(e); }}
                                    helperText='Ingresa Costo del Taller'
                                    onChange={handleChangePrecio}
                                />
                            </div>
                            <Grid container spacing={1} sx={{ justifyContent: 'left', alignItems: 'left' }}>
                                <Grid item xs={12} sm={12} md={6}>
                                    <div>Fecha de Inscripción</div>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} sx={{ justifyContent: 'center', alignItems: 'center' }}>
                                <Grid item xs={12} sm={12} md={6}>
                                    <TextField fullWidth margin="normal"
                                        id="FechaInicio"
                                        label="Fecha de inicio"
                                        type="date"
                                        variant="standard"
                                        sx={{ width: '100%' }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        value={state.fecha_inicio}
                                        helperText='Ingresa tu fecha de Inicio'
                                        onChange={handleChangeFechaInicio}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <TextField fullWidth margin="normal"
                                        id="FechaInicio"
                                        label="Fecha Fin"
                                        type="date"
                                        variant="standard"
                                        sx={{ width: '100%' }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        value={state.fecha_fin}
                                        helperText='Ingresa tu fecha final'
                                        onChange={handleChangeFechaFin}
                                    />
                                </Grid>
                            </Grid>
                            <div style={{ color: 'red', textAlign: 'center', height: '40px' }}>{state.error}</div>
                            <Grid container spacing={2} sx={{ justifyContent: 'center', alignItems: 'center', paddingTop: '4%' }}>
                                <Grid item xs="auto">
                                    <ColorButton variant="outlined"
                                        type='button'
                                        endIcon={
                                            <CloseIcon />
                                        }
                                        onClick={handleClose}>
                                        Cancelar
                                    </ColorButton>
                                </Grid>
                                <Grid item xs={5}></Grid>
                                <Grid item xs="auto">
                                    <ColorButton variant="outlined"
                                        type='button'
                                        endIcon={
                                            <SaveIcon />
                                        }
                                        onClick={() => subirTaller()}>
                                        Guardar
                                    </ColorButton>
                                </Grid>
                            </Grid>
                        </div>
                    </Modal>


                    <Modal
                        open={state.openDetalle}
                        onClose={handleCloseDetalle}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <div className="boxModals" >


                            <div className='mb-3'>
                                {state.talleresDisciplina.length !== 0 ?
                                    <>
                                        <h2>{state.talleresDisciplina[0].nombredisciplina}</h2>
                                        <hr></hr>
                                        {state.talleresDisciplina.map((item) => {
                                            return (
                                                <>
                                                    <Accordion>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="detalle"
                                                            id="detalle"
                                                            style={{ backgroundColor: "#69164a", color: "white" }}
                                                        >
                                                            <Typography >Taller: {item.nombretaller}</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>


                                                            <table className="table table-bordered table-hover table-checkable">
                                                                
                                                                <tbody>
                                                                    <tr className='bg-rosaClaro'>
                                                                        <td className="text-left"><b>Profesor</b>:</td>
                                                                        <td className="text-center"><b>{item.profesor}</b></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="text-left"><b>Fecha inicio inscripción:</b></td>
                                                                        <td className="text-center"><b>{item.fechaincrpcioninicio}</b></td>
                                                                    </tr>
                                                                    <tr className='bg-rosaClaro'>
                                                                        <td className="text-left"><b>Fecha fin inscripción:</b></td>
                                                                        <td className="text-center"><b>{item.fechaincrpcionfin}</b></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="text-left"><b>Precio:</b></td>
                                                                        <td className="text-center"><b>$ {item.preciotaller ? toMexico(item.preciotaller) : 'N/A'}</b></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="text-left"><b>Inicio de taller:</b></td>
                                                                        <td className="text-center"><b>{item.inicio}</b></td>
                                                                    </tr>
                                                                    <tr className='bg-rosaClaro'>
                                                                        <td className="text-left"><b>Fin de taller:</b></td>
                                                                        <td className="text-center"><b>{item.fin}</b></td>
                                                                    </tr>
                                                                    <tr className='bg-rosaClaro'>
                                                                        <td className="text-left"><b>Cupo del taller:</b></td>
                                                                        <td className="text-center"><b>{item.capacidad}</b></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="text-left"><b>Edad mínima:</b></td>
                                                                        <td className="text-center"><b>{item.rango}</b></td>
                                                                    </tr>
                                                                    <tr className='bg-rosaClaro'>
                                                                        <td className="text-left"><b>Horario:</b></td>
                                                                        <td className="text-center"><b>{item.horariotaller}</b></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="text-left"><b>Nivel:</b></td>
                                                                        <td className="text-center"><b>{item.nivel}</b></td>
                                                                    </tr>
                                                                    <tr className='bg-rosaClaro'>
                                                                        <td className="text-left"><b>Salón:</b></td>
                                                                        <td className="text-center"><b>{item.salon}</b></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </>
                                            )
                                        })}
                                    </>
                                    :
                                    <>
                                        <hr></hr>
                                        <h3 className="text-center">Esta disciplina no cuenta con talleres activos...</h3>
                                        <hr></hr>
                                    </>
                                }
                            </div>



                            <Grid container spacing={2} sx={{ justifyContent: 'center', alignItems: 'center', paddingTop: '4%' }}>
                                <Grid item xs="auto">
                                </Grid>
                                <Grid item xs={5}></Grid>
                                <Grid item xs="auto">
                                    <ColorButton variant="outlined"
                                        type='button'
                                        endIcon={
                                            <CloseIcon />
                                        }
                                        onClick={handleCloseDetalle}>
                                        Cerrar
                                    </ColorButton>
                                </Grid>
                            </Grid>
                        </div>
                    </Modal>

                    <Modal
                        open={modalClone}
                        onClose={handleCloseClone}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <div className="boxModals" >
                            <Grid item xs={12} sm={12} md={12}>
                                <h4>Duplicar Talleres</h4>
                                <hr></hr>
                            </Grid>
                            <div className='row mb-3'>
                                <div className='col-sm-6 mt-3'>
                                    <TextField
                                        fullWidth
                                        size='small'
                                        select
                                        label="Taller Periodo Inicial"
                                        name='periodoI'
                                        value={dataClone.periodoI}
                                        onChange={handleChangeClone}
                                    >
                                        {
                                        state.periodos.map(element=>{
                                            return(
                                                <MenuItem 
                                                key={`ìd_${element.id}_`}
                                                value={element.id}
                                                >
                                                    {`${element.fecha_inicio} a ${element.fecha_fin} - ${element.status}`}
                                                </MenuItem>
                                            )
                                        })

                                        }
                                    </TextField>
                                </div>
                                <div className='col-sm-6 mt-3'>
                                    <TextField
                                        fullWidth
                                        size='small'
                                        select
                                        label="Taller Periodo Final"
                                        name="periodoF"
                                        value={dataClone.periodoF}
                                        onChange={handleChangeClone}
                                    >
                                        {
                                        state.periodos.map(element=>{
                                            return(
                                                <MenuItem 
                                                key={`ìd_${element.id}_`}
                                                value={element.id}
                                                disabled={dataClone.periodoI === element.id}
                                                >
                                                    {`${element.fecha_inicio} a ${element.fecha_fin} - ${element.status}`}
                                                </MenuItem>
                                            )
                                        })

                                        }
                                    </TextField>
                                </div>
                                <div className='col-md-12 my-2j'>
                                    <Divider textAlign='left'>Nuevo Periodo</Divider>
                                </div>
                                <div className='col-sm-6  mt-3'>
                                    <TextField
                                        InputLabelProps={{shrink:true}}
                                        type='date'
                                        fullWidth
                                        size='small'
                                        label="Fecha Inscripcion Inicio"
                                        onChange={handleChangeClone}
                                        value={dataClone.fechaI}
                                        name='fechaI'
                                        inputProps={{
                                            min:dayjs().format('YYYY-MM-DD')
                                        }}
                                    />
                                </div>
                                <div className='col-sm-6  mt-3'>
                                    <TextField
                                        InputLabelProps={{shrink:true}}
                                        type='date'
                                        fullWidth
                                        size='small'
                                        label="Fecha Inscripcion Final"
                                        onChange={handleChangeClone}
                                        value={dataClone.fechaF}
                                        name='fechaF'
                                        inputProps={{
                                            min:dayjs().format('YYYY-MM-DD')
                                        }}
                                    />
                                </div>
                                <div className='col-md-12  mt-3'>
                                <div style={{ width: 'fit-content', padding: '0 9px' }} className="mx-auto cta cta--guinda" onClick={
                                    () => {
                                       getClone(dataClone);
                                    }
                                }><div className="me-2"><IconUI size={'20px'} color={'white'}><SaveAsIcon /></IconUI></div>Guardar</div>
                                </div>
                            </div>
                        </div>
                    </Modal>

                </div>
                : <div style={{ height: 'calc(100vh - 80px)' }}><span class="loader"></span></div>}

        </>);
}

export default Tpp; 