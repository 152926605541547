import OverLayIU from "./overlay_ui";
import React, { useEffect, useContext } from "react";
import IconUI from "./icon_ui";
import { IoIosCloseCircle } from "react-icons/io";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { useNavigate } from "react-router-dom";
import { services } from "../../services/api";
import { NotificacionContext } from "../../context/notificacion_context";

export default function PopUp({ onClose, dataItem }) {
  const { showNotification } = useContext(NotificacionContext);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [dataItem]);

  const handleClose = () => {
    onClose();
  };

  const inscribiralumno = async (data) => {
    if (localStorage.getItem("token")) {
      if (data.idtaller) {
        const dataIscripcion = await services(
          "GET",
          `usuarios/crearinscripcionalumno?taller=${data.id}`,
          null
        );

        if (dataIscripcion.status === 200) {
          if (dataIscripcion.data.created === false) {
            showNotification(
              "warning",
              "Alerta!",
              dataIscripcion.data.mensaje,
              null,
              4
            );
          } else if (dataIscripcion.data?.create === false) {
            showNotification(
              "warning",
              "Alerta!",
              dataIscripcion.data.error,
              null,
              4
            );
          } else {
            showNotification(
              "success",
              "Acción Exitosa!",
              "El alumno se inscribió correctamente.",
              null,
              4
            );
            navigate("/mis-cursos");
          }
        } else {
          showNotification(
            "error",
            "Algo salió mal",
            dataIscripcion.error,
            null,
            4
          );
        }
      } else {
        showNotification(
          "error",
          "Algo salió mal",
          "No se pudo acceder a la Base de datos, consulta al administrador.",
          null,
          4
        );
      }
    } else {
      navigate("/login");
    }
  };

  return (
    <>
      <OverLayIU onClick={handleClose} />
      <div className="wizard">
        <div className="bg-morado d-flex align-items-center">
          <div className="col">
            <div className="m-3 text-big text-white text-bold">
              {dataItem.nombretaller}
            </div>
          </div>
          <div className="cursor-pointer me-3" onClick={handleClose}>
            <IconUI color={"#ffff"} size={"30px"}>
              <IoIosCloseCircle />
            </IconUI>
          </div>
        </div>
        <div className="container">
          <div className="row mt-3">
            <div className="col mt-2 ">
              <div style={{ height: "350px", width: "100%", }}>
                <Swiper
                  navigation={true}
                  modules={[Navigation]}
                  className="mySwiper"
                >
                  {dataItem.fotos.map((foto, i) => (
                    <SwiperSlide key={i}>
                      <div className="d-flex align-items-center fotopopUp">
                        <img
                          src={
                            foto.foto
                              ? foto.foto
                              : "https://images.pexels.com/photos/3971983/pexels-photo-3971983.jpeg?auto=compress&cs=tinysrgb&w=600"
                          }
                          alt=""
                        />
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
            <div className="col">
              <div className="card my-3" style={{ height: "120px" }}>
                <div className="text--morado text-center text-bold borderBottomTitulo">
                  NIVEL
                </div>
                <div className="text-center">{dataItem.nivel}</div>
              </div>
              <div className="card" style={{ height: "200px" }}>
                <div className="my-3 text--morado text-center text-bold borderBottomTitulo">
                  DESCRIPCIÓN
                </div>
                <div>{dataItem.descripciontaller}</div>
              </div>
            </div>
          </div>
          <div className="my-3 text--morado text-bold borderBottomTitulo">
            {" "}
            INFORMACIÓN DE TALLER{" "}
          </div>
          <ul>
            <li>
              <div>Horario: {dataItem.horariotaller}</div>
            </li>
            <li>
              <div>Edad: {dataItem.rango}</div>
            </li>
            <li>
              <div>Maestro: {dataItem.profesor}</div>
            </li>
            <li>
              <div>Salón: {dataItem.salon}</div>
            </li>
            <li>
              <div>Cupo máximo: {dataItem.capacidad}</div>
            </li>
            <li>
              <div>
                Fecha inicio inscripción: {dataItem.fechaincrpcioninicio}
              </div>
            </li>
            <li>
              <div>Fecha fin inscripción: {dataItem.fechaincrpcionfin}</div>
            </li>
          </ul>
          <div className="my-3 d-flex justify-content-center">
            <div
              className="cta cta--guinda cta--small"
              onClick={() => {
                window.localStorage.setItem("tallerSelected", dataItem.id);
                inscribiralumno(dataItem);
                handleClose();
              }}
            >
              INSCRIBIRME
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
