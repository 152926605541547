import { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import PopUp from "../componets/ui/popUp";
import { services } from "../services/api";

const Taller = () => {
  const [data, setdata] = useState({
    talleres: [],
  });
  const [state, setState] = useState({
    showPopUp: false,
    infoItem: {},
    loading: true,
  });

  const dataFake = [
    {
      disciplina: "No hay talleres actualmente, consulte más tarde.",
      talleres: [],
    },
  ];

  const consumoApiTalleres = async () => {
    try {
      let talleres = await services("GET", `usuarios/listarperiodostallers`);

      if (talleres.status === 200) {
        if (talleres.data.length === 0) {
          setdata({ talleres: dataFake });
        } else {
          setdata({ talleres: talleres.data });
        }
      }

    } catch (error) {
      console.error("Error al consumir la API:", error);
    } finally {
      setState((prevState) => ({
        ...prevState,
        loading: false,}))
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      consumoApiTalleres();
    }

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {state.loading ? (
        <div style={{ height: "calc(100vh - 80px)" }}>
          <span className="loader"></span>
        </div>
      ) : (
      <div className="d-flex flex-column align-items-center full-screen-container">
        {state.showPopUp && (
          <PopUp
            dataItem={state.infoItem}
            onClose={() => setState({ ...state, showPopUp: false })}
          />
        )}
        <div className="container-fluid mt-4">
          <div className="row d-flex align-items-center justify-content-center my-5">
            <div className="col-12 col-md-8 col-lg-6 mb-4 order-2 order-md-1">
              <div className="card mx-2">
                <div className="card-body">
                  <h2 className="card-title text-center mb-3">TALLERES</h2>
                  <div className="d-flex flex-column">
                    <Accordion defaultActiveKey={["0"]} alwaysOpen>
                      {data.talleres.map((item1, i) => (
                        <Accordion.Item eventKey={i.toString()} key={i}>
                          <Accordion.Header>
                            {item1.disciplina}
                          </Accordion.Header>
                          <Accordion.Body>
                            {item1.talleres.map((item2, j) => (
                              <div key={j} className="mb-2">
                                <div
                                  className="link mb-1 bg-rosaClaro"
                                  onClick={() =>
                                    setState({
                                      ...state,
                                      showPopUp: true,
                                      infoItem: item2,
                                    })
                                  }
                                >
                                  {item2.nombretaller}
                                </div>
                                <div className="text-gray">
                                  {item2.horariotaller}
                                </div>
                              </div>
                            ))}
                          </Accordion.Body>
                        </Accordion.Item>
                      ))}
                    </Accordion>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-4 col-lg-6 mb-4 d-flex justify-content-center order-1 order-md-2">
              <div className="Imagen-Taller" alt="taller_img" />
            </div>
          </div>
        </div>
      </div>
      )}
    </>
  );
};

export default Taller;
