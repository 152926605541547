import React, { useContext, useEffect, useState } from 'react';
import Noticias from "../componets/NoticiasComponent";
import Usuarios from "../componets/UsuariosComponent";
import { GlobalContext } from '../context/global_context';
import { useNavigate } from "react-router-dom";
import Slider from '../componets/SliderComponent';
import Talleres from '../componets/TalleresComponent';
import Tpp from '../componets/TppComponent';
import Inscripciones from '../componets/InscripcionesComponent';
import Disciplinas from '../componets/DisciplinasComponent';
import Periodos from '../componets/PeriodosComponent';
import Reporte from '../componets/ReportComponent';



const AdminView = () => {

  const { GlobalState } = useContext(GlobalContext);
  const navigate = useNavigate();
  // eslint-disable-next-line 
  const [state, setState] = useState({
    disciplinas: [],
    periodos: []
  })


  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      if (localStorage.getItem('rol') !== 'Admin') {
        navigate("/");
      }

    }

    return () => {
      isMounted = false
    }
    // eslint-disable-next-line 
  }, [])

  return (
    <>
        
      {GlobalState.vista === 'Noticias' ?
        <Noticias />
        : <></>}
      {GlobalState.vista === 'Registro Usuarios' ?
        <Usuarios />
        : <></>}
      {GlobalState.vista === 'Slider' ?
        <Slider />
        : <></>}
      {GlobalState.vista === 'Talleres' ?
        <Talleres />
        : <></>}
      {GlobalState.vista === 'TPP' ?
        <Tpp />
        : <></>}
      {GlobalState.vista === 'Inscripciones' ?
        <Inscripciones />
        : <></>}
      {GlobalState.vista === 'Reporte' ?
        <Reporte />
        : <></>}
      {GlobalState.vista === 'Disciplinas y Periodos' ?
        <>
          <Disciplinas data={state.disciplinas} />
          <Periodos data={state.periodos} />
        </>
        : <></>}
    </>

  )
}

export default AdminView