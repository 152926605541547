import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import LogoBlanco from '../assets/images/logoBlanco.png';
import LogoSecum from "../assets/images/CULTURA-HORIZONTAL.png";
import LogoMichoacan from "../assets/images/Veda Horizontal.png";
import { GlobalContext } from "../context/global_context";
import { onlyNumberPhone, } from "../utils/inputsRules";
import MenuIcon from "@mui/icons-material/Menu";
import LogoGuinda from "../assets/images/logoNegro.png";
import "../styles/estilos.css";
import Grid from "@mui/material/Grid";
import { TextField } from "@mui/material";
import { services } from "../services/api";
import { NotificacionContext } from "../context/notificacion_context";

const Profile = (userSelected) => {
  const { setGlobalState, GlobalState } = useContext(GlobalContext);
  const { showNotification } = useContext(NotificacionContext);
  const [state, setState] = useState({
    data: [],
    confpass:''
  });
  const [stateError, setStateError] = useState({})
  const [currentEmail, setCurrentEmail] = useState("");
  const [currentPaaword, setCurrentPassword] = useState("");
  const obtenerData = async () => {
    const idUser = localStorage.getItem("id");
    await services("GET", `usuarios/obtener-usuario/${idUser}`).then(
      (response) => {
        if (response.status === 200) {
          setState({
            ...state,
            data: response.data.data,
          });
          setCurrentEmail(response.data.data.email);
          setCurrentPassword(response.data.data.password);
        }
      }
    );
  };

  const logoutEmail = () => {
    console.log(currentEmail !== state.data.email ||
      currentPaaword !== state.data.password)
    if (
      currentEmail !== state.data.email ||
      currentPaaword !== state.data.password
    ) {

      showNotification("success", "se inicia proceso de cerrar sesión", null,null, 4);
      localStorage.clear();
      window.location.href = "/login";
    }
  };

  const actualizarUsuario = () => {
    
    if (state.confpass === state.data.password) {
      let body = {
        telefono: state.data.telefono,
        email: state.data.email,
        password: state.data.password,
      };
      console.log(body)
      const curp = localStorage.getItem("curp");

      services("POST", `usuarios/actializarusuario/${curp}`, body).then(
        (response) => {
          if (response.status === 200) {
            if (response.data?.updated === true) {
              showNotification("success", "Usuario Actualizado", "", null, 4);
              setGlobalState({
                ...GlobalState,
                buscar: false,
                modalAlumno: false,
              });
              setState({
                ...state,
                data: {
                  ...state,
                  email: state.data.email,
                  telefono: state.data.telefono,
                  password: state.data.password,
                },
              });
              setTimeout(() => {
                logoutEmail();
              }, 3000);
            } else {
              //  setLoader(false);
              showNotification("error", response.data.error, null, null,4);
            }
          }
        }
      );
    }else{
      showNotification("error", "Las contraseñas deben coincidir", null, null,3);
    }
  };

  const handleTelefono = (e) => {
    setState({
      ...state,
      data: { ...state.data, telefono: e.target.value },
    });
  };

  const handleEmail = (e) => {
    setState({
      ...state,
      data: { ...state.data, email: e.target.value },
    });
  };

  const handlePassword = (e) => {
    setState({
      ...state,
      data: { ...state.data, password: e.target.value },
    });
  };

  const handleConfirmPass = (e) => {
    setState({ ...state, confpass: e.target.value });
  };

  useEffect(() => {
    obtenerData();
  }, []);

  return (
    <>
      <div className="d-flex flex-column container my-5">
        <h1 className="mt-4">Mi perfil</h1>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              fullWidth
              margin="normal"
              // error={stateError.curpError}
              id="curp"
              label="Curp"
              type="text"
              // disabled
              variant="standard"
              value={state.data?.CURP}
              disabled
              InputLabelProps={{
                shrink: true,
              }}
              helperText="Ingresa tu Curp"
            // disabled={editable ? true : false}
            // onKeyPress={(event) => {
            //   maxLengthLimitInput(event, 17, "curp");
            // }}
            // onChange={handleCurp}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <TextField
              fullWidth
              margin="normal"
              // error={stateError.nombreError}
              id="nombre"
              label="Nombre(s)"
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              disabled
              variant="standard"
              value={state.data?.nombre}
              helperText="Ingresa tu Nombre"
            // onKeyPress={(event) => {
            //     onlyLetter(event, 20);
            // }}
            // onChange={handleNombre}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <TextField
              fullWidth
              margin="normal"
              // error={stateError.apellidoPError}
              id="apellidoP"
              disabled
              label="Apellido Paterno"
              type="text"
              variant="standard"
              InputLabelProps={{
                shrink: true,
              }}
              value={state.data?.apaterno}
              helperText="Ingresa tu Apellido"
            // onKeyPress={(event) => {
            //   onlyLetter(event, 20);
            // }}
            // onChange={handleApellidoP}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              fullWidth
              margin="normal"
              // error={stateError.apellidoMError}
              id="apellidoM"
              disabled
              label="Apellido Materno"
              type="text"
              variant="standard"
              InputLabelProps={{
                shrink: true,
              }}
              value={state.data?.amaterno}
              helperText="Ingresa tu Apellido"
            // onKeyPress={(event) => {
            //   onlyLetter(event, 20);
            // }}
            // onChange={handleApellidoM}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <TextField
              fullWidth
              disabled
              margin="normal"
              id="fechaNacimiento"
              label="Fecha de Nacimento"
              type="text"
              variant="standard"
              // sx={{ width: 220 }}
              InputLabelProps={{
                shrink: true,
              }}
              value={state.data?.fechanacimiento}
              helperText="Ingresa tu fecha de nacimiento"
            // onChange={handleFechaNacimiento}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <TextField
              fullWidth
              margin="normal"
              // error={stateError.ocupacionError}
              id="ocupacion"
              label="Ocupación"
              disabled
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              variant="standard"
              value={state.data?.ocupacion}
              helperText="Ingresa tu Ocupación"
            // onChange={handleOcupacion}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              fullWidth
              margin="normal"
              // error={stateError.calleError}
              id="calle"
              label="Calle"
              InputLabelProps={{
                shrink: true,
              }}
              type="text"
              disabled
              variant="standard"
              value={state.data?.calle}
              helperText="Ingresa tu calle"
            // onChange={handleCalle}
            />
          </Grid>
          {/* Numero de casa */}

          <Grid item xs={12} md={6} lg={4}>
            <TextField
              fullWidth
              margin="normal"
              // error={stateError.numeroError}
              id="numero"
              InputLabelProps={{
                shrink: true,
              }}
              label="Número de casa"
              type="text"
              disabled
              variant="standard"
              value={state.data?.numero}
              helperText="Ingresa tu número"
            // onChange={handleNumero}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <TextField
              fullWidth
              margin="normal"
              // error={stateError.coloniaError}
              id="colonia"
              label="Colonia"
              type="text"
              disabled
              variant="standard"
              InputLabelProps={{
                shrink: true,
              }}
              value={state.data?.colonia}
              helperText="Ingresa tu Colonia"
            // onChange={handleColonia}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <TextField
              fullWidth
              margin="normal"
              // error={stateError.municipioError}
              id="municipio"
              label="Municipio"
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              disabled
              variant="standard"
              value={state.data?.municipio}
              helperText="Ingresa tu Municipio"
            // onChange={handleMucipio}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <TextField
              fullWidth
              margin="normal"
              error={stateError.telefonoError}
              id="telefono"
              label="Teléfono"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{ maxLength: 10 }}
              type="text"
              variant="standard"
              value={state.data?.telefono}
              onKeyPress={onlyNumberPhone}
              helperText="Ingresa tu Teléfono"
              onChange={handleTelefono}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <TextField
              fullWidth
              margin="normal"
              error={stateError.emailError}
              id="email"
              label="Correo Electrónico"
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              variant="standard"
              helperText="Ingresa tu Correo Electrónico"
              value={state.data?.email}
              // onKeyPress={(event) => {
              //   maxLengthLimitInput(event, 55, "email");
              // }}
              onChange={handleEmail}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <TextField
              fullWidth
              margin="normal"
              error={stateError.passError}
              id="pass"
              label="Contraseña Nueva"
              type="password"
              InputLabelProps={{
                shrink: true,
              }}
              variant="standard"
              helperText="Ingresa tu Contraseña"
              value={state.data?.password}
              onChange={handlePassword}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            {/* {editable ? null : ( */}
            <TextField
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              error={stateError.confpassError}
              id="confpass"
              label="Confirma Contraseña"
              type="password"
              variant="standard"
              helperText="Confirma tu Contraseña"
              value={state.confpass}
              onChange={handleConfirmPass}
            />
          </Grid>
        </Grid>
        <div className="my-5 d-flex justify-content-center align-items-center">
          <div
            className="cta cta--guinda cta--small"
            onClick={actualizarUsuario}
          >
            Actualizar
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
