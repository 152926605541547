import React, { useEffect, useState } from 'react'
import LoaderComponent from './LoaderComponent'
import { services } from '../services/api';

import DataTable from './DataTableComponent';

function ReportComponent() {
    const [loader, setLoader] = useState(false);
    const [state, setState] = useState({
        data: []
    })
    const api = async () => {
        setLoader(true)

        const dataDos = await services('GET', 'usuarios/formato', null);
        if (dataDos.status === 200) {
            setState({ ...state, data: dataDos.data });
        }
        setLoader(false)
    }
    useEffect(() => {
        let isMounted = true
        if (isMounted) {

            api();
        }

        return () => {
            isMounted = false
        }
        // eslint-disable-next-line
    }, [])

    const columns = [
        {
            name: "fecha_pago",
            label: "Fecha Pago",
            options: {
                filter: false,
                sort: false,
                searchable: true,

                customBodyRenderLite: (dataIndex, i) => {
                    let imagen = state.data[dataIndex].fecha_pago;
                    return (<div style={{ height: '60px', width: "100px" }} className={i % 2 === 0 ? "bg-rosaClaro d-flex align-items-center justify-content-center" : "d-flex align-items-center justify-content-center"}>{imagen}</div>)
                }
            }
        },
        {
            name: "consecutivo",
            label: "Folio Consecutivo",
            options: {
                filter: false,
                searchable: true,

                sort: false,
                customBodyRenderLite: (dataIndex, i) => {
                    let imagen = state.data[dataIndex].consecutivo;
                    return (<div style={{ height: '60px', width: "100px" }} className={i % 2 === 0 ? "bg-rosaClaro d-flex align-items-center justify-content-center" : "d-flex align-items-center justify-content-center"}>{imagen}</div>)
                }
            }
        },
        {
            name: "lineacaptura",
            label: "Linea Captura",
            options: {
                filter: false,
                searchable: true,

                sort: false,
                customBodyRenderLite: (dataIndex, i) => {
                    let imagen = state.data[dataIndex].lineacaptura;
                    return (<div style={{ height: '60px', width: "180px" }} className={i % 2 === 0 ? "bg-rosaClaro d-flex align-items-center justify-content-center" : "d-flex align-items-center justify-content-center"}>{imagen}</div>)
                }
            }
        },
        {
            name: "importe",
            label: "Importe",
            options: {
                filter: false,
                sort: false,
                searchable: true,

                customBodyRenderLite: (dataIndex, i) => {
                    let imagen = state.data[dataIndex].importe;
                    return (<div style={{ height: '60px', width: "100px" }} className={i % 2 === 0 ? "bg-rosaClaro d-flex align-items-center justify-content-center" : "d-flex align-items-center justify-content-center"}>${imagen}</div>)
                }
            }
        },
        {
            name: "CURP",
            label: "CURP",
            options: {
                filter: false,
                searchable: true,
                sort: false,
                customBodyRenderLite: (dataIndex, i) => {
                    let Descripcion = state.data[dataIndex].CURP;
                    return (<div style={{ height: '60px' }} className={i % 2 === 0 ? "bg-rosaClaro d-flex align-items-center justify-content-center" : "d-flex align-items-center justify-content-center"}>{Descripcion}</div>)
                }
            }
        },
        {
            name: "nombre",
            label: "Nombre",
            align: "center",
            options: {
                searchable: true,
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex, i) => {
                    let nombre = state.data[dataIndex].nombre;
                    return (<div style={{ height: '60px', width: "170px" }} className={i % 2 === 0 ? "bg-rosaClaro d-flex align-items-center justify-content-center" : "d-flex align-items-center justify-content-center"}>{nombre}</div>)
                }
            }
        },
        

       
        {
            name: "nombreTaller",
            label: "Nombre Taller",
            options: {
                filter: false,
                sort: false,
                searchable: true,
                customBodyRenderLite: (dataIndex, i) => {
                    let imagen = state.data[dataIndex].nombreTaller;
                    return (<div style={{ height: '60px', width: "180px" }} className={i % 2 === 0 ? "bg-rosaClaro d-flex align-items-center justify-content-center" : "d-flex align-items-center justify-content-center"}>{imagen}</div>)
                }
            }
        },
        {
            name: "tipo",
            label: "Tipo Descuento",
            options: {
                filter: false,
                searchable: true,
                sort: false,
                customBodyRenderLite: (dataIndex, i) => {
                    let imagen = state.data[dataIndex].tipo ?? "N/A";
                    return (<div style={{ height: '60px', width: "180px" }} className={i % 2 === 0 ? "bg-rosaClaro d-flex align-items-center justify-content-center" : "d-flex align-items-center justify-content-center"}>{imagen}</div>)
                }
            }
        },
        


    ];


    return (
        <>
            {loader &&
                <LoaderComponent />
            }
            <div style={{ height: 'calc(100vh - 80px)' }} >
                <div className='container'>

                    <DataTable
                        data={state.data}
                        title="Reporte"
                        columns={columns}
                        more={{
                            searchPlaceholder: "Buscar por Nombre"
                        }}
                    />

                </div>
            </div>
        </>
    )
}

export default ReportComponent