import React from "react";
import MUIDataTable from "mui-datatables";
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";

const DataTable = ({ data, columns, title, more = {} }) => {
  let options = {
    ...more,
    print: false,
    viewColumns: false,
    filter: false,
    selectableRows: "none",
    tableBodyHeight: "auto",

    textLabels: {
      body: {
        noMatch: "Lo sentimos, no hay coincidencias en la busqueda",
        toolTip: "Clasificar",
        columnHeaderTooltip: (column) => `Clasificar por ${column.label}`,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Filas por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtrar tabla",
      },
      filter: {
        all: "Todo",
        title: "Filtros",
        reset: "Restaurar",
      },
      viewColumns: {
        title: "Ver columnas",
        titleAria: "Mostrar/Ocultar columnas en la tabla",
      },
      selectedRows: {
        text: "columna(s) selecciona(as)",
        delete: "Eliminar",
        deleteAria: "Eliminar filas seleccionadas",
      },
    },
    responsive: "standard",
    downloadOptions: {
      filename: "Casa-de-la-Cultura.csv",
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
      utf8WithBom: false,
    },
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid className="container mt-5 mb-5">
        <Grid item xs={8}>
          <MUIDataTable
            style={{ Overflow: "hidden" }}
            title={title}
            data={data}
            columns={columns}
            options={options}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default DataTable;
