import React, { useContext, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import PaymentIcon from "@mui/icons-material/Payment";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import { useNavigate, useParams } from "react-router-dom";
import { GlobalContext } from "../context/global_context";

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText("#005cbf"),
  backgroundColor: "#606770",
  "&:hover": {
    backgroundColor: "#606770",
  },
}));

const ColorButtonSecond = styled(Button)(({ theme }) => ({
  color: "#606770",
  backgroundColor: "#ffc3d0",
  border: "none",
  "&:hover": {
    transform: "scale(1.005)",
    boxShadow: "3px 3px 6px 0px rgba(0, 0, 0, 0.2)",
  },
}));

const PagoLinea = () => {
  const { GlobalState, setGlobalState } = useContext(GlobalContext);
  const [state, setState] = useState({
    loading: true,
  });
  let { id } = useParams();
  const navigate = useNavigate();

  const pdfFake = "";
  const { CONCEPTOPLINEA, FECHAPLINEA, FORMATO, IMPORTE, LINEA_CAPTURA } =
    GlobalState.lineaCaptura;

  const TOKEN = GlobalState.lineaCaptura.TOKEN
    ? GlobalState.lineaCaptura.TOKEN
    : "";

  const regresar = () => {
    setGlobalState({ ...GlobalState, openpagoLinea: false });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setState({ loading: false });
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="h-100">
      {!true ? (
        regresar()
      ) : (
        <Container maxWidth="md">
          <Box sx={{ height: "auto", paddingTop: "6%" }}>
            <Box>
              <Grid
                container
                spacing={1}
                sx={{ justifyContent: "center", alignItems: "center" }}
              >
                <Grid item xs="auto">
                  <ColorButtonSecond
                    variant="outlined"
                    onClick={regresar}
                    endIcon={<KeyboardReturnIcon />}
                  >
                    Regresar
                  </ColorButtonSecond>
                </Grid>
                <Grid item xs="auto">
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href={FORMATO ? `${FORMATO}/2` : pdfFake}
                  >
                    <ColorButtonSecond
                      variant="outlined"
                      endIcon={<CloudDownloadIcon />}
                    >
                      {GlobalState.lineaCaptura.stastustaller === "pendiente"
                        ? "Descargar mi formato de pago"
                        : "Descargar mi orden de pago"}
                    </ColorButtonSecond>
                  </a>
                </Grid>
                <Grid item xs="auto">
                  <form action={process.env.REACT_APP_URLPAGO} method="post">
                    <input
                      type="hidden"
                      name="concepto"
                      value={CONCEPTOPLINEA}
                    />
                    <input
                      type="hidden"
                      name="lcaptura"
                      value={LINEA_CAPTURA}
                    />
                    <input type="hidden" name="monto" value={IMPORTE} />
                    <input
                      type="hidden"
                      name="fecha_vencimiento"
                      value={FECHAPLINEA}
                    />
                    <input type="hidden" name="tkn" value={TOKEN} />
                    <input
                      type="hidden"
                      name="urlRetorno"
                      value={process.env.REACT_APP_URLREDIRECT}
                    />
                    <input type="hidden" name="email" value="" />
                    {GlobalState.lineaCaptura.stastustaller === "pendiente" && (
                      <ColorButton
                        variant="outlined"
                        type="submit"
                        endIcon={<PaymentIcon />}
                      >
                        Pagar en Línea
                      </ColorButton>
                    )}
                  </form>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <div className="pt-2 border-bottom--guinda text-big mb-2 text-bold">
            {GlobalState.lineaCaptura.stastustaller === "pendiente"
              ? "Formato de pago"
              : "Orden de Pago"}
          </div>
          {state.loading ? (
            <div style={{ height: "calc(100vh - 80px)" }}>
              <span className="loader"></span>
            </div>
          ) : (
            <div className="vh100 d-flex justify-content-center align-items-center pt-3 pb-3">
              <object
                data={FORMATO}
                type="application/pdf"
                className="responsive-pdf"
                title="PDF Preview"
              >
                <p>
                  Hubo un error al mostrar el PDF. Da clic{" "}
                  <a href={FORMATO}>aquí {" "} </a> para descargar tu formato.
                </p>
              </object>
            </div>
          )}
        </Container>
      )}
    </div>
  );
};

export default PagoLinea;
