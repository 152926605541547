import React, { useEffect, useState, useContext } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import { Form } from "react-bootstrap";
import { BsFacebook } from "react-icons/bs";
import { AiFillInstagram, AiFillYoutube } from "react-icons/ai";
import IconUI from "../componets/ui/icon_ui";
import { services } from "../services/api";
import emailjs from "@emailjs/browser";
import { NotificacionContext } from "../context/notificacion_context";

function Home() {
  const [state, setState] = useState({
    noticias: [],
    imagenesSlider: [],
    usuarioname: "",
    mensaje: "",
    correoUsuario: "",
    loading: true,
  });

  const { showNotification } = useContext(NotificacionContext);

  const cosnumoapi = async () => {
    try {
      let noticiasapi = await services("GET", `usuarios/listarnoticias`, null);
      let sliderapi = await services("GET", `usuarios/listarelider`, null);
      if (noticiasapi.status === 200 && sliderapi.status === 200) {
        setState((prevState) => ({
          ...prevState,
          noticias: noticiasapi.data,
          imagenesSlider: sliderapi.data,
        }));
      }
      
    } catch (error) {
      console.error("Error al consumir la API:", error);
    } finally {
      setState((prevState) => ({
        ...prevState,
        loading: false,}))
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      cosnumoapi();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const limpiarForm = () => {
    setState((prevState) => ({
      ...prevState,
      usuarioname: "",
      mensaje: "",
      correoUsuario: "",
    }));
  };

  const setNombre = (e) => {
    setState((prevState) => ({
      ...prevState,
      usuarioname: e.target.value,
    }));
  };
  const setcorreo = (e) => {
    setState((prevState) => ({
      ...prevState,
      correoUsuario: e.target.value,
    }));
  };
  const setMensaje = (e) => {
    setState((prevState) => ({
      ...prevState,
      mensaje: e.target.value,
    }));
  };

  const Correo = () => {
    if (
      state.usuarioname === "" ||
      state.correoUsuario === "" ||
      state.mensaje === ""
    ) {
      showNotification(
        "warning",
        "Faltan campos",
        "Favor de llenar todos los campos",
        null,
        4
      );
      return false;
    } else {
      emailjs
        .send(
          process.env.REACT_APP_SERVICE,
          process.env.REACT_APP_TEMPLATE,
          {
            Email: "direccion.casadeculturamorelia@gmail.com",
            Mensaje: state.mensaje,
            usuario: state.usuarioname,
            correoUsuario: state.correoUsuario,
          },
          process.env.REACT_APP_KEY_CORREOS
        )
        .then(
          (response) => {
            limpiarForm();
            showNotification(
              "success",
              "Mensaje enviado",
              "Nos contactaremos contigo lo más pronto posible",
              null,
              4
            );
          },
          (err) => {}
        );
      return true;
    }
  };

  return (
    <>
      {state.loading ? (
        <div style={{ height: "calc(100vh - 80px)" }}>
          <span className="loader"></span>
        </div>
      ) : (
      <>
        <div style={{ marginTop: "-.9%" }}>
          <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
            {state.imagenesSlider.map((item, index) => (
              <SwiperSlide key={index}>
                <div
                  className="slider-imagen"
                  style={{
                    backgroundImage: `url(${item.rutafoto})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                ></div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        <div className="container-verde text-center text-white mb-3">
          <div className="text-bold text-big">Bienvenidos</div>
          <div>Página Oficial de la Casa de la Cultura de Morelia</div>
        </div>

        <div className="container mt-5">
          <div className="my-3 text--morado text-bold borderBottomTitulo">
            NOTICIAS
          </div>
        </div>

        <div className="container my-3">
          <div className="d-flex flex-wrap">
            <div className="col-12 col-lg-8 card mb-3">
              {state.noticias.length !== 0 &&
                state.noticias.map((item) => (
                  <div
                    className="d-flex flex-column flex-md-row align-items-center mb-5"
                    key={item.id}
                  >
                    <img
                      className="img--news mb-4"
                      style={{
                        width: "100%",
                        maxWidth: "230px",
                        objectFit: "cover",
                      }}
                      src={item.rutafoto}
                      alt={item.titulo}
                    />
                    <div className="ms-3">
                      <p>{item.titulo}</p>
                      <p className="descripcionHome">{item.descripcion}</p>
                      <p>Fecha: {item.fecha}</p>
                    </div>
                  </div>
                ))}
            </div>
            <div className="col-12 col-lg-3 offset-lg-1 card redesSocialesCard">
              <div className="my-3 text--morado text-bold borderBottomTitulo">
                REDES SOCIALES
              </div>
              <div className="d-flex justify-content-center">
                <div
                  className="iconoBtn"
                  onClick={() =>
                    window.open(
                      "https://www.facebook.com/CasaCulturaMorelia/",
                      "_blank"
                    )
                  }
                >
                  <IconUI size={"30px"}>
                    <BsFacebook />
                  </IconUI>
                </div>
                <div
                  className="mx-3 iconoBtn"
                  onClick={() =>
                    window.open(
                      "https://www.instagram.com/casaculturamorelia/",
                      "_blank"
                    )
                  }
                >
                  <IconUI size={"35px"}>
                    <AiFillInstagram />
                  </IconUI>
                </div>
                <div
                  className="iconoBtn"
                  onClick={() =>
                    window.open(
                      "https://www.youtube.com/@casadelaculturademorelia",
                      "_blank"
                    )
                  }
                >
                  <IconUI size={"35px"}>
                    <AiFillYoutube />
                  </IconUI>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-verdePastel">
          <div className="container my-3" id="contacto">
            <div className="py-3 mb-3 text--morado text-bold borderBottomTitulo">
              CONTACTO
            </div>
            <div className="row py-3">
              <div className="col-lg-6 col-md-12 mb-3">
                <Form>
                  <Form.Group className="mb-3 colorForm">
                    <Form.Control
                      value={state.usuarioname}
                      onChange={setNombre}
                      type="name"
                      placeholder="Nombre"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3 colorForm">
                    <Form.Control
                      value={state.correoUsuario}
                      onChange={setcorreo}
                      type="email"
                      placeholder="Email"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3 colorForm">
                    <Form.Control
                      value={state.mensaje}
                      onChange={setMensaje}
                      as="textarea"
                      rows={6}
                      placeholder="Escribir Mensaje..."
                    />
                  </Form.Group>
                </Form>
                <div className="d-flex justify-content-center my-4">
                  <div onClick={Correo} className="cta cta--guinda">
                    Enviar
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="mapa">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3756.149584198377!2d-101.19357098567082!3d19.706254686729455!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842d0e6e14a85e8d%3A0x99426f8279c5a30a!2sCasa%20de%20la%20Cultura%20de%20Morelia!5e0!3m2!1ses!2smx!4v1676309636771!5m2!1ses!2smx"
                    title="map"
                    width="100%"
                    height="300px"
                    loading="lazy"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
      )}
    </>
  );
}

export default Home;
