import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {
  maxLengthLimitInput,
  onlyNumberPhone,
  onlyLetter,
} from "../utils/inputsRules";
import { NotificacionContext } from "../context/notificacion_context";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { Box } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import { styled } from "@mui/material/styles";
import { services } from "../services/api";
import LoaderComponent from "../componets/LoaderComponent";

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "1px inset #383838",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19)",
  p: 4,
  height: "auto",
  width: {
    xs: "80%", 
    md: 500, 
  },
};

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText("#005cbf"),
  backgroundColor: "#606770",
  "&:hover": {
    backgroundColor: "#606770",
  },
  color: theme.palette.getContrastText("#005cbf"),
  backgroundColor: "#4A001F",
  "&:hover": {
    backgroundColor: "#4A001F",
  },
}));

export const CrearCuentaView = () => {
  const navigate = useNavigate();

  const { showNotification } = useContext(NotificacionContext);

  const [boton1Habilitado, setBoton1Habilitado] = useState(true);
  const [boton2Habilitado, setBoton2Habilitado] = useState(false);

  const [loader, setLoader] = useState(false);

  const handleClickBoton1 = () => {
    setBoton1Habilitado(false);
    setBoton2Habilitado(true);
    window.open(process.env.PUBLIC_URL + "/AVISO_DISCAPACIDAD.pdf");
    if (validForm()) {
      showNotification(
        "Leido",
        "Haz Leido Aviso De Discapacidad",
        null,
        null,
        10
      );
    }
  };

  const handleClickBoton2 = () => {
    setBoton2Habilitado(false);
    setBoton1Habilitado(true);
  };

  const [stateError, setStateError] = useState({
    curpError: false,
    nombreError: false,
    apellidoPError: false,
    apellidoMError: false,
    fechaNacimientoError: false,
    ocupacionError: false,
    calleError: false,
    numeroError: false,
    coloniaError: false,
    municipioError: false,
    telefonoError: false,
    emailError: false,
    passError: false,
    confpassError: false,
    terminosCondicionesError: false,
  });
  const [state, setState] = useState({
    selected: "",
    selected2: "radio4",
    curp: "",
    nombre: "",
    apellidoP: "",
    apellidoM: "",
    fechaNacimiento: "",
    ocupacion: "",
    calle: "",
    numero: "",
    colonia: "",
    municipio: "",
    telefono: "",
    email: "",
    pass: "",
    confpass: "",
    sexo: "",
    discapacidad: "",
    open: false,
    openRadio: false,
    terminosCondiciones: false,
  });

  const openmodal = () => {
    setState({ ...state, open: true });
  };

  const openmodalRadio = () => {
    setState({ ...state, openRadio: true, selected2: "radio3" });
  };

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const handleCloseRadio = () => {
    setState({ ...state, openRadio: false });
  };

  const handleCurp = (e) => {
    setState({ ...state, curp: e.target.value });

    if (e.target.value.length > 17) {
      setLoader(true);

      services("POST", `usuarios/renapo/${e.target.value}`, null)
        .then((response) => {
          if (response.status === 404) {
            showNotification(
              "error",
              "Curp Incorrecta o invalida",
              null,
              null,
              4
            );
          }
          setState({
            ...state,
            curp: e.target.value,
            nombre: response.data.nombres,
            apellidoP: response.data.primerApellido,
            apellidoM: response.data.segundoApellido,
            sexo: response.data.sexo,
            fechaNacimiento: response.data.fechNac,
          });
          setLoader(false);
        })
        .catch((reponse) => {
          setLoader(false);
        });
    } else {
      setState({
        ...state,
        curp: e.target.value,
        nombre: "",
        apellidoP: "",
        apellidoM: "",
        sexo: "",
        fechaNacimiento: "",
      });
    }
  };

  const handleNombre = (e) => {
    setState({ ...state, nombre: e.target.value });
  };

  const handleApellidoP = (e) => {
    setState({ ...state, apellidoP: e.target.value });
  };

  const handleApellidoM = (e) => {
    setState({ ...state, apellidoM: e.target.value });
  };

  const handleFechaNacimiento = (e) => {
    setState({ ...state, fechaNacimiento: e.target.value });
  };

  const handleOcupacion = (e) => {
    setState({ ...state, ocupacion: e.target.value });
  };

  const handleCalle = (e) => {
    setState({ ...state, calle: e.target.value });
  };

  const handleNumero = (e) => {
    setState({ ...state, numero: e.target.value });
  };

  const handleColonia = (e) => {
    setState({ ...state, colonia: e.target.value });
  };

  const handleMucipio = (e) => {
    setState({ ...state, municipio: e.target.value });
  };

  const handleTelefono = (e) => {
    setState({ ...state, telefono: e.target.value });
  };

  const handleEmail = (e) => {
    setState({ ...state, email: e.target.value });
  };

  const handlePassword = (e) => {
    setState({ ...state, pass: e.target.value });
  };

  const handleConfirmPass = (e) => {
    setState({ ...state, confpass: e.target.value });
  };

  const handleTerminosCondiciones = (e) => {
    setState({ ...state, terminosCondiciones: e.target.value });
  };

  const validForm = () => {
    let curpError = state.curp === "";
    let nombreError = state.nombre === "";
    let apellidoPError = state.apellidoP === "";
    let apellidoMError = state.apellidoM === "";
    let fechaNacimientoError = state.fechaNacimiento === "";
    let ocupacionError = state.ocupacion === "";
    let calleError = state.calle === "";
    let numeroError = state.numero === "";
    let coloniaError = state.colonia === "";
    let municipioError = state.municipio === "";
    let telefonoError = state.telefono === "";
    let emailError = state.email === "";
    let passError = state.pass === "";
    let confpassError = state.confpass === "";
    let terminosCondicionesError = state.terminosCondiciones;

    setStateError({
      curpError: curpError,
      nombreError: nombreError,
      apellidoPError: apellidoPError,
      apellidoMError: apellidoMError,
      fechaNacimientoError: fechaNacimientoError,
      ocupacionError: ocupacionError,
      calleError: calleError,
      numeroError: numeroError,
      coloniaError: coloniaError,
      municipioError: municipioError,
      telefonoError: telefonoError,
      emailError: emailError,
      passError: passError,
      confpassError: confpassError,
      terminosCondicionesError: terminosCondicionesError,
    });

    return (
      !curpError &&
      !nombreError &&
      !apellidoPError &&
      !apellidoMError &&
      !fechaNacimientoError &&
      !ocupacionError &&
      !calleError &&
      !numeroError &&
      !coloniaError &&
      !municipioError &&
      !telefonoError &&
      !emailError &&
      !passError &&
      !confpassError &&
      !terminosCondicionesError
    );
  };

  const handleCrearCuenta = () => {
    if (validForm()) {
      openmodal();
    } else {
      showNotification(
        "warning",
        "Faltan Campos",
        "Favor de ingresar todos los campos",
        null,
        4
      );
    }
  };

  // const getRenapo = async (curp) => {
  //     const dataRenapo = await services("POST", `usuarios/renapo/${curp}`, null)
  //     if (dataRenapo.status !== 200) {
  //         showNotification("error", "Ups... Algo salió mal", 'Intentalo más tarde', null, 4);
  //     } else {
  //         setState({ ...state, curp, nombre: dataRenapo.data.nombres, apellidoP: dataRenapo.data.primerApellido, apellidoM: dataRenapo.data.segundoApellido, sexo: dataRenapo.data.sexo, fechaNacimiento: dataRenapo.data.fechNac })
  //     }

  // }

  const getCrearCuenta = async () => {
    setLoader(true);

    const dataCrearCuenta = await services(
      "POST",
      `usuarios/crearusuario?discapacidad=${
        state.selected2 === "radio3" ? 1 : 0
      }&nombre=${state.nombre}&apaterno=${state.apellidoP}&amaterno=${
        state.apellidoM
      }&email=${state.email}&telefono=${state.telefono}&CURP=${
        state.curp
      }&calle=${state.calle}&password=${state.pass}&colonia=${
        state.colonia
      }&municipio=${state.municipio}&sexo=${state.sexo}&ocupacion=${
        state.ocupacion
      }&fechanacimiento=${state.fechaNacimiento}&numero=${
        state.numero
      }&password=${state.pass}`,
      null
    );

    if (dataCrearCuenta.status === 200) {
      if (dataCrearCuenta.data?.created === true) {
        setState({
          ...state,
          nombre: dataCrearCuenta.data.nombre,
          apellidoP: dataCrearCuenta.data.apaterno,
          apellidoM: dataCrearCuenta.data.amaterno,
          email: dataCrearCuenta.data.email,
          telefono: dataCrearCuenta.data.telefono,
          calle: dataCrearCuenta.data.calle,
          pass: dataCrearCuenta.data.password,
          colonia: dataCrearCuenta.data.colonia,
          municipio: dataCrearCuenta.data.municipio,
          sexo: dataCrearCuenta.data.sexo,
          fechaNacimiento: dataCrearCuenta.data.fechNac,
        });

        handleClose();
        showNotification("success", "¡Cuenta creada con éxito!", null, null, 4);
        navigate("/login");
        setLoader(false);
      } else {
        setLoader(false);
        handleClose();
        showNotification("error", dataCrearCuenta.data.error, null, null, 4);
      }
    }
  };

  return (
    <>
      {loader && <LoaderComponent />}

      <div className="container mobile-container">
        <div className="cardFormulario crearCuentaForm">
          <div className="text-big text--gray">Crear Cuenta</div>

          <div >
            <Grid container spacing={2}>
            <Grid item lg={4} md={4} xs={12}>
                <TextField
                  fullWidth
                  margin="normal"
                  error={stateError.curpError}
                  id="curp"
                  label="Curp"
                  type="text"
                  variant="standard"
                  value={state.curp.toUpperCase()}
                  helperText="Ingresa tu Curp"
                  onKeyPress={(event) => {
                    maxLengthLimitInput(event, 18, "curp");
                  }}
                  inputProps={{
                    style: { textTransform: "uppercase", color: "#606770" },
                  }}
                  onChange={handleCurp}
                />
              </Grid>
              <Grid item lg={4} md={4} xs={12}>
                <TextField
                  fullWidth
                  margin="normal"
                  error={stateError.nombreError}
                  id="nombre"
                  disabled
                  label="Nombre(s)"
                  type="text"
                  variant="standard"
                  value={state.nombre}
                  helperText="Ingresa tu Nombre"
                  // onKeyPress={(event) => {
                  //     onlyLetter(event, 55);
                  // }}
                  onChange={handleNombre}
                />
              </Grid>

              <Grid item lg={4} md={4} xs={12}>
                <TextField
                  fullWidth
                  margin="normal"
                  error={stateError.apellidoPError}
                  id="apellidoP"
                  disabled
                  label="Apellido Paterno"
                  type="text"
                  variant="standard"
                  value={state.apellidoP}
                  helperText="Ingresa tu Apellido"
                  onKeyPress={(event) => {
                    onlyLetter(event, 55);
                  }}
                  onChange={handleApellidoP}
                />
              </Grid>
              <Grid item lg={4} md={4} xs={12}>
                <TextField
                  fullWidth
                  margin="normal"
                  error={stateError.apellidoMError}
                  id="apellidoM"
                  disabled
                  label="Apellido Materno"
                  type="text"
                  variant="standard"
                  value={state.apellidoM}
                  helperText="Ingresa tu Apellido"
                  onKeyPress={(event) => {
                    onlyLetter(event, 55);
                  }}
                  onChange={handleApellidoM}
                />
              </Grid>
              <Grid item lg={4} md={4} xs={12}>
                <TextField
                  fullWidth
                  margin="normal"
                  id="fechaNacimiento"
                  label="Fecha de Nacimento"
                  type="text"
                  disabled
                  variant="standard"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={state.fechaNacimiento}
                  helperText="Ingresa tu fecha de nacimiento"
                  onChange={handleFechaNacimiento}
                />
              </Grid>
              <Grid item lg={4} md={4} xs={12}>
                <TextField
                  fullWidth
                  margin="normal"
                  error={stateError.ocupacionError}
                  id="ocupacion"
                  label="Ocupación"
                  type="text"
                  variant="standard"
                  value={state.ocupacion}
                  helperText="Ingresa tu Ocupación"
                  onKeyPress={(event) => {
                    onlyLetter(event, 55);
                  }}
                  onChange={handleOcupacion}
                />
              </Grid>

              <Grid item lg={4} md={4} xs={12}>
                <TextField
                  fullWidth
                  margin="normal"
                  error={stateError.calleError}
                  id="calle"
                  label="Calle"
                  type="text"
                  variant="standard"
                  value={state.calle}
                  helperText="Ingresa tu calle"
                  onKeyPress={(event) => {
                    onlyLetter(event, 55);
                  }}
                  onChange={handleCalle}
                />
              </Grid>

              {/* Numero de casa */}
              <Grid item lg={4} md={4} xs={12}>
                <TextField
                  fullWidth
                  margin="normal"
                  error={stateError.numeroError}
                  id="numero"
                  label="Número de casa"
                  type="text"
                  variant="standard"
                  value={state.numero}
                  helperText="Ingresa tu número"
                  onChange={handleNumero}
                />
              </Grid>

              <Grid item lg={4} md={4} xs={12}>
                <TextField
                  fullWidth
                  margin="normal"
                  error={stateError.coloniaError}
                  id="colonia"
                  label="Colonia"
                  type="text"
                  variant="standard"
                  value={state.colonia}
                  helperText="Ingresa tu Colonia"
                  onKeyPress={(event) => {
                    onlyLetter(event, 55);
                  }}
                  onChange={handleColonia}
                />
              </Grid>

              <Grid item lg={4} md={4} xs={12}>
                <TextField
                  fullWidth
                  margin="normal"
                  error={stateError.municipioError}
                  id="municipio"
                  label="Municipio"
                  type="text"
                  variant="standard"
                  value={state.municipio}
                  helperText="Ingresa tu Municipio"
                  onKeyPress={(event) => {
                    onlyLetter(event, 55);
                  }}
                  onChange={handleMucipio}
                />
              </Grid>

              <Grid item lg={4} md={4} xs={12}>
                <TextField
                  fullWidth
                  margin="normal"
                  error={stateError.telefonoError}
                  id="telefono"
                  label="Teléfono"
                  inputProps={{ maxLength: 10 }}
                  type="text"
                  variant="standard"
                  value={state.telefono}
                  onKeyPress={onlyNumberPhone}
                  helperText="Ingresa tu Teléfono"
                  onChange={handleTelefono}
                />
              </Grid>

              <Grid item lg={4} md={4} xs={12}>
                <TextField
                  fullWidth
                  margin="normal"
                  error={stateError.emailError}
                  id="email"
                  label="Correo Electrónico"
                  type="text"
                  variant="standard"
                  helperText="Ingresa tu Correo Electrónico"
                  value={state.email}
                  onKeyPress={(event) => {
                    maxLengthLimitInput(event, 55, "email");
                  }}
                  onChange={handleEmail}
                />
              </Grid>

              <Grid item lg={4} md={4} xs={12}>
                <TextField
                  fullWidth
                  margin="normal"
                  error={stateError.passError}
                  id="pass"
                  label="Contraseña Nueva"
                  type="password"
                  variant="standard"
                  helperText="Ingresa tu Contraseña"
                  value={state.pass}
                  onChange={handlePassword}
                />
              </Grid>

              {/* {console.log(state.pass)} */}

              <Grid item lg={4} md={4} xs={12}>
                <TextField
                  fullWidth
                  margin="normal"
                  error={stateError.confpassError}
                  id="confpass"
                  label="Confirma Contraseña"
                  type="password"
                  variant="standard"
                  helperText="Confirma tu Contraseña"
                  value={state.confpass}
                  onChange={handleConfirmPass}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <label>Sexo</label>
                <div className="d-flex flex-column flex-lg-row aling-content-center my-4">
                  <div className="rb1 ">
                    <input
                      id="M"
                      type="radio"
                      disabled={true}
                      onChange={() => {
                        setState({ ...state, sexo: "M" });
                      }}
                      checked={state.sexo === "M"}
                    />
                    <label for="M">Femenino</label>
                  </div>

                  <div className="rb-1">
                    <input
                      id="H"
                      type="radio"
                      disabled={true}
                      onChange={() => {
                        setState({ ...state, sexo: "H" });
                      }}
                      checked={state.sexo === "H"}
                    />{" "}
                    <label for="H">Masculino</label>
                  </div>
                </div>
              </Grid>
              {/* 
              <Grid item lg={4} md={4} xs={12}>
                <label>Sexo</label>
                <div className="d-flex flex-column flex-lg-row  aling-content-center mt-4 z-3">
                  <div className="rb1 ">
                    <input
                      value={state.sexo}
                      id="radio1"
                      type="radio"
                      checked={state.sexo === "M"}
                    />
                    <label for="radio1">Femenino</label>
                  </div>

                  <div className="rb1">
                    <input
                      value={state.sexo}
                      id="radio2"
                      type="radio"
                      checked={state.sexo === "H"}
                    />
                    <label for="radio2">Masculino</label>
                  </div>
                </div>
              </Grid> */}

              <Grid justifyContent="center" item lg={4} xs={6}>
                <label>¿Cuentas con alguna Discapacidad?</label>
                <div className="d-flex flex-column flex-lg-row aling-content-center my-4">
                  <div className="rb1 ">
                    <input
                      id="radio3"
                      type="radio"
                      checked={state.selected2 === "radio3"}
                      onChange={() => {
                        openmodalRadio();
                      }}
                    />
                    <label for="radio3">Si</label>
                  </div>

                  <div className="rb-1">
                    <input
                      id="radio4"
                      type="radio"
                      checked={state.selected2 === "radio4"}
                      onChange={() => {
                        setState({ ...state, selected2: "radio4" });
                      }}
                    />{" "}
                    <label for="radio4">No</label>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
          <div>
            <div className="mt-4 d-flex justify-content-center align-items-center">
              <div
                className="cta cta--guinda cta--small"
                onClick={handleCrearCuenta}
              >
                Crear Cuenta
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        open={state.openRadio}
        onClose={null}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal}>
          <Grid
            container
            spacing={2}
            sx={{
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "2%",
            }}
          >
            <Grid item>
              <InputLabel id="label-titulo" style={{ paddingBottom: "10%" }}>
                Aviso Discapacidad Importante
              </InputLabel>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={6}
            sx={{ justifyContent: "center", alignItems: "center" }}
          >
            <Grid
              item
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {boton1Habilitado && (
                <ColorButton
                  className="me-2"
                  onClick={handleClickBoton1}
                  disabled={!boton1Habilitado}
                >
                  Abrir PDF
                </ColorButton>
              )}
              {boton2Habilitado && (
                <ColorButton
                  variant="outlined"
                  type="button"
                  onClick={() => {
                    handleClickBoton2();
                    handleCloseRadio();
                  }}
                  disabled={!boton2Habilitado}
                  value={state.terminosCondiciones}
                  error={stateError.terminosCondicionesError}
                  onChange={handleTerminosCondiciones}
                >
                  Aceptar
                </ColorButton>
              )}
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <Modal
        open={state.open}
        onClose={null}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal}>
          <Grid
            container
            spacing={2}
            sx={{ justifyContent: "center", alignItems: "center" }}
          >
            <Grid item xs={9}>
              <InputLabel id="label-titulo" style={{ paddingBottom: "10%" }}>
                ¿Estás Seguro de Crear la cuenta?
              </InputLabel>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            sx={{
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "4%",
            }}
          >
            <Grid item xs="auto">
              <ColorButton
                variant="outlined"
                type="button"
                onClick={handleClose}
              >
                NO
              </ColorButton>
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs="auto">
              <ColorButton
                variant="outlined"
                type="button"
                onClick={() => {
                  getCrearCuenta();
                }}
              >
                Si Quiero
              </ColorButton>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};
