import { useContext, useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {
  maxLengthLimitInput,
  onlyNumberPhone,
  onlyLetter,
} from "../utils/inputsRules";
import { NotificacionContext } from "../context/notificacion_context";
import { services } from "../services/api";
import { GlobalContext } from "../context/global_context";
import LoaderComponent from "./LoaderComponent";

export const FormularioRegistro = ({
  editable,
  userSelected,
  rol,
  rolSelected,
}) => {
  const { showNotification } = useContext(NotificacionContext);

  const { setGlobalState, GlobalState } = useContext(GlobalContext);

  const [stateError, setStateError] = useState({
    curpError: false,
    nombreError: false,
    apellidoPError: false,
    apellidoMError: false,
    fechaNacimientoError: false,
    ocupacionError: false,
    calleError: false,
    coloniaError: false,
    municipioError: false,
    telefonoError: false,
    emailError: false,
    passError: false,
    confpassError: false,
    terminosCondicionesError: false,
    selected2Error: false,
    numeroError: false,
  });

  const [state, setState] = useState({
    selected: "",
    selected2: "radio4",
    curp: userSelected ? userSelected.CURP : "",
    nombre: userSelected ? userSelected.nombre : "",
    apellidoP: userSelected ? userSelected.apaterno : "",
    apellidoM: userSelected ? userSelected.amaterno : "",
    fechaNacimiento: userSelected ? userSelected.fechanacimiento : "",
    ocupacion: userSelected ? userSelected.ocupacion : "",
    calle: userSelected ? userSelected.calle : "",
    colonia: userSelected ? userSelected.colonia : "",
    municipio: userSelected ? userSelected.municipio : "",
    telefono: userSelected ? userSelected.telefono : "",
    email: userSelected ? userSelected.email : "",
    pass: userSelected ? userSelected.password : "",
    confpass: "",
    sexo: "",
    open: false,
    openRadio: false,
    terminosCondiciones: false,
    openEditar: false,
    base: "",
    numero: userSelected ? userSelected.numero : "",
  });

  const [loader, setLoader] = useState(false);
  const [renapoResponse, setRenapoResponse] = useState(true);

  const handleCurp = (e) => {
    setState({ ...state, curp: e.target.value });

    if (e.target.value.length > 17) {
      setLoader(true);
      services("POST", `usuarios/renapo/${e.target.value}`, null)
        .then((response) => {
          if (response.status === 404) {
            showNotification(
              "error",
              "Curp Incorrecta o invalida",
              null,
              null,
              4
            );
          }
          setState({
            ...state,
            curp: e.target.value,
            nombre: response.data.nombres,
            apellidoP: response.data.primerApellido,
            apellidoM: response.data.segundoApellido,
            sexo: response.data.sexo,
            fechaNacimiento: response.data.fechNac,
          });
          setLoader(false);
        })
        .catch((response) => {
          setLoader(false);
        });
    } else {
      setRenapoResponse(true);
      setState({
        ...state,
        curp: e.target.value,
        nombre: "",
        apellidoP: "",
        apellidoM: "",
        sexo: "",
        fechaNacimiento: "",
      });
    }
  };

  const handleNombre = (e) => {
    setState({ ...state, nombre: e.target.value });
  };

  const handleApellidoP = (e) => {
    setState({ ...state, apellidoP: e.target.value });
  };

  const handleApellidoM = (e) => {
    setState({ ...state, apellidoM: e.target.value });
  };

  const handleFechaNacimiento = (e) => {
    setState({ ...state, fechaNacimiento: e.target.value });
  };

  const handleOcupacion = (e) => {
    setState({ ...state, ocupacion: e.target.value });
  };

  const handleCalle = (e) => {
    setState({ ...state, calle: e.target.value });
  };

  const handleNumero = (e) => {
    setState({ ...state, numero: e.target.value });
  };

  const handleColonia = (e) => {
    setState({ ...state, colonia: e.target.value });
  };

  const handleMucipio = (e) => {
    setState({ ...state, municipio: e.target.value });
  };

  const handleTelefono = (e) => {
    setState({ ...state, telefono: e.target.value });
  };

  const handleEmail = (e) => {
    setState({ ...state, email: e.target.value });
  };

  const handlePassword = (e) => {
    setState({ ...state, pass: e.target.value });
  };

  const handleConfirmPass = (e) => {
    setState({ ...state, confpass: e.target.value });
  };
  // const handleClose = () => {
  //     setState({
  //         ...state,
  //         open: false,
  //     });

  // }

  const validForm = () => {
    if (editable) {
      let ocupacionError = state.ocupacion === "";
      let calleError = state.calle === "";
      let coloniaError = state.colonia === "";
      let municipioError = state.municipio === "";
      let telefonoError = state.telefono === "";
      let selected2Error = state.selected2 === "";

      setStateError({
        ocupacionError: ocupacionError,
        calleError: calleError,
        coloniaError: coloniaError,
        municipioError: municipioError,
        telefonoError: telefonoError,
        selected2Error: selected2Error,
      });
      return (
        !ocupacionError &&
        !calleError &&
        !coloniaError &&
        !municipioError &&
        !telefonoError &&
        !selected2Error
      );
    } else {
      let curpError = state.curp === "";
      let nombreError = state.nombre === "";
      let apellidoPError = state.apellidoP === "";
      let apellidoMError = state.apellidoM === "";
      let fechaNacimientoError = state.fechaNacimiento === "";
      let ocupacionError = state.ocupacion === "";
      let calleError = state.calle === "";
      let numeroError = state.numero === "";
      let coloniaError = state.colonia === "";
      let municipioError = state.municipio === "";
      let telefonoError = state.telefono === "";
      let emailError = state.email === "";
      let passError = state.pass === "";
      let confpassError = state.confpass === "";
      let terminosCondicionesError = state.terminosCondiciones;
      let selected2Error =
        state.selected2 === "radio4" || state.selected2 === "radio3"
          ? false
          : true;

      setStateError({
        curpError: curpError,
        nombreError: nombreError,
        apellidoPError: apellidoPError,
        apellidoMError: apellidoMError,
        fechaNacimientoError: fechaNacimientoError,
        ocupacionError: ocupacionError,
        calleError: calleError,
        numeroError,
        coloniaError: coloniaError,
        municipioError: municipioError,
        telefonoError: telefonoError,
        emailError: emailError,
        passError: passError,
        confpassError: confpassError,
        terminosCondicionesError: terminosCondicionesError,
        selected2Error:
          selected2Error === "radio4" || selected2Error === "radio3"
            ? false
            : true,
      });

      return (
        !curpError &&
        !nombreError &&
        !apellidoPError &&
        !apellidoMError &&
        !fechaNacimientoError &&
        !ocupacionError &&
        !calleError &&
        !numeroError &&
        !coloniaError &&
        !municipioError &&
        !telefonoError &&
        !emailError &&
        !passError &&
        !confpassError &&
        !terminosCondicionesError &&
        !selected2Error
      );
    }
  };

  const handleCrearCuenta = () => {
    if (validForm()) {
      if (editable) {
        setLoader(true);
        services(
          "POST",
          `usuarios/actializarusuario/${userSelected.CURP}?discapacidad=${state.selected2 === "radio3" ? 1 : 0
          }&ocupacion=${state.ocupacion}&calle=${state.calle}&colonia=${state.colonia
          }&municipio=${state.municipio}&telefono=${state.telefono}&nombre=${state.nombre
          }&CURP=${state.curp}&apaterno=${state.apellidoP}&amaterno=${state.apellidoM
          }&fechanacimiento=${state.fechaNacimiento}&numero=${state.numero
          }&email=${state.email}&password=${state.pass}`
        ).then((response) => {
          if (response.status === 200) {
            if (response.data?.updated === true) {
              showNotification("success", "Usuario Actualizado", "", null, 4);
              setGlobalState({
                ...GlobalState,
                buscar: false,
                modalAlumno: false,
              });
            } else {
              setLoader(false);
              showNotification("error", response.data.error, null, 4);
            }
          }
        });
      } else {
        setLoader(true);
        services(
          "POST",
          `usuarios/crearusuario?base=${state.base}&discapacidad=${state.selected2 === "radio3" ? 1 : 0
          }&nombre=${state.nombre}&apaterno=${state.apellidoP}&amaterno=${state.apellidoM
          }&email=${state.email}&telefono=${state.telefono}&CURP=${state.curp
          }&calle=${state.calle}&password=${state.pass}&colonia=${state.colonia
          }&municipio=${state.municipio}&sexo=${state.sexo}&ocupacion=${state.ocupacion
          }&fechanacimiento=${state.fechaNacimiento}&tipo=${GlobalState.rolInscripcion
          }&numero=${state.numero}&password=${state.pass}`,
          null
        ).then((response) => {
          if (response.status === 200) {
            if (response.data?.created === true) {
              showNotification("success", "Usuario Registrado", "", null, 4);
              setGlobalState({
                ...GlobalState,
                buscar: true,
                modalAlumno: false,
              });
            } else {
              setLoader(false);
              showNotification("error", response.data.error, null, 4);
            }
          }
        });
      }
    } else {
      showNotification(
        "warning",
        "Faltan Campos",
        "favor de ingresar todos los campos",
        null,
        4
      );
    }
  };

  return (
    <>
      {loader && <LoaderComponent />}
      <div className="w-100 ">
        <div className="d-flex flex-column cardFormulario">
          <div className="container">
            <div className="text-big text--gray">Datos de Registro</div>
            <Grid container spacing={2}>
              <>
                <Grid item xs={6} lg={4}>
                  <TextField
                    fullWidth
                    margin="normal"
                    error={stateError.curpError}
                    id="curp"
                    label="Curp"
                    type="text"
                    variant="standard"
                    value={state.curp.toUpperCase()}
                    helperText="Ingresa tu Curp"
                    // disabled={editable ? true : false}
                    onKeyPress={(event) => {
                      maxLengthLimitInput(event, 17, "curp");
                    }}
                    onChange={handleCurp}
                  />
                </Grid>
                {/* {rolSelected === "Profesor" ||
                  rolSelected === "Admin" ? null : (
                  <Grid item xs={6} lg={4}>
                    <TextField
                      fullWidth
                      margin="normal"
                      error={stateError.curpError}
                      id="curp"
                      label="Curp"
                      type="text"
                      variant="standard"
                      value={state.curp.toUpperCase()}
                      helperText="Ingresa tu Curp"
                      // disabled={editable ? true : false}
                      onKeyPress={(event) => {
                        maxLengthLimitInput(event, 17, "curp");
                      }}
                      onChange={handleCurp}
                    />
                  </Grid>
                )} */}

                <Grid item xs={6} lg={4}>
                  <TextField
                    fullWidth
                    margin="normal"
                    error={stateError.nombreError}
                    id="nombre"
                    label="Nombre(s)"
                    type="text"
                    variant="standard"
                    value={state.nombre}
                    helperText="Ingresa tu Nombre"
                    // onKeyPress={(event) => {
                    //     onlyLetter(event, 20);
                    // }}
                    onChange={handleNombre}
                  />
                </Grid>
              </>

              <Grid item xs={6} lg={4}>
                <TextField
                  fullWidth
                  margin="normal"
                  error={stateError.apellidoPError}
                  id="apellidoP"
                  label="Apellido Paterno"
                  type="text"
                  variant="standard"
                  value={state.apellidoP}
                  helperText="Ingresa tu Apellido"
                  onKeyPress={(event) => {
                    onlyLetter(event, 20);
                  }}
                  onChange={handleApellidoP}
                />
              </Grid>
              <Grid item xs={6} lg={4}>
                <TextField
                  fullWidth
                  margin="normal"
                  error={stateError.apellidoMError}
                  id="apellidoM"
                  label="Apellido Materno"
                  type="text"
                  variant="standard"
                  value={state.apellidoM}
                  helperText="Ingresa tu Apellido"
                  onKeyPress={(event) => {
                    onlyLetter(event, 20);
                  }}
                  onChange={handleApellidoM}
                />
              </Grid>
              {/* {rolSelected === "Profesor" || rolSelected === "Admin" ? null : (
                <Grid item xs={6} lg={4}>
                  <TextField
                    fullWidth
                    margin="normal"
                    id="fechaNacimiento"
                    label="Fecha de Nacimento"
                    type="text"
                    variant="standard"
                    // sx={{ width: 220 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={state.fechaNacimiento}
                    helperText="Ingresa tu fecha de nacimiento"
                    onChange={handleFechaNacimiento}
                  />
                </Grid>
              )} */}
              <Grid item xs={6} lg={4}>
                  <TextField
                    fullWidth
                    margin="normal"
                    id="fechaNacimiento"
                    label="Fecha de Nacimento"
                    type="text"
                    variant="standard"
                    // sx={{ width: 220 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={state.fechaNacimiento}
                    helperText="Ingresa tu fecha de nacimiento"
                    onChange={handleFechaNacimiento}
                  />
                </Grid>

              <Grid item xs={6} lg={4}>
                <TextField
                  fullWidth
                  margin="normal"
                  error={stateError.ocupacionError}
                  id="ocupacion"
                  label="Ocupación"
                  type="text"
                  variant="standard"
                  value={state.ocupacion}
                  helperText="Ingresa tu Ocupación"
                  onChange={handleOcupacion}
                />
              </Grid>
              <Grid item xs={6} lg={4}>
                <TextField
                  fullWidth
                  margin="normal"
                  error={stateError.calleError}
                  id="calle"
                  label="Calle"
                  type="text"
                  variant="standard"
                  value={state.calle}
                  helperText="Ingresa tu calle"
                  onChange={handleCalle}
                />
              </Grid>
              {/* Numero de casa */}
              {/* {rolSelected === "Profesor" || rolSelected === "Admin" ? null : (
                <Grid item xs={6} lg={4}>
                  <TextField
                    fullWidth
                    margin="normal"
                    error={stateError.numeroError}
                    id="numero"
                    label="Número de casa"
                    type="text"
                    variant="standard"
                    value={state.numero}
                    helperText="Ingresa tu número"
                    onChange={handleNumero}
                  />
                </Grid>
              )} */}
              <Grid item xs={6} lg={4}>
                  <TextField
                    fullWidth
                    margin="normal"
                    error={stateError.numeroError}
                    id="numero"
                    label="Número de casa"
                    type="text"
                    variant="standard"
                    value={state.numero}
                    helperText="Ingresa tu número"
                    onChange={handleNumero}
                  />
                </Grid>

              <Grid item xs={6} lg={4}>
                <TextField
                  fullWidth
                  margin="normal"
                  error={stateError.coloniaError}
                  id="colonia"
                  label="Colonia"
                  type="text"
                  variant="standard"
                  value={state.colonia}
                  helperText="Ingresa tu Colonia"
                  onChange={handleColonia}
                />
              </Grid>

              <Grid item xs={6} lg={4}>
                <TextField
                  fullWidth
                  margin="normal"
                  error={stateError.municipioError}
                  id="municipio"
                  label="Municipio"
                  type="text"
                  variant="standard"
                  value={state.municipio}
                  helperText="Ingresa tu Municipio"
                  onChange={handleMucipio}
                />
              </Grid>

              <Grid item xs={6} lg={4}>
                <TextField
                  fullWidth
                  margin="normal"
                  error={stateError.telefonoError}
                  id="telefono"
                  label="Teléfono"
                  inputProps={{ maxLength: 10 }}
                  type="text"
                  variant="standard"
                  value={state.telefono}
                  onKeyPress={onlyNumberPhone}
                  helperText="Ingresa tu Teléfono"
                  onChange={handleTelefono}
                />
              </Grid>

              {/* {rolSelected === "Profesor" || rolSelected === "Admin" ? null : (
                <Grid item xs={6} lg={4}>
                  <TextField
                    fullWidth
                    margin="normal"
                    error={stateError.emailError}
                    id="email"
                    label="Correo Electrónico"
                    type="text"
                    variant="standard"
                    helperText="Ingresa tu Correo Electrónico"
                    value={state.email}
                    onKeyPress={(event) => {
                      maxLengthLimitInput(event, 55, "email");
                    }}
                    onChange={handleEmail}
                  />
                </Grid>
              )} */}
              <Grid item xs={6} lg={4}>
                  <TextField
                    fullWidth
                    margin="normal"
                    error={stateError.emailError}
                    id="email"
                    label="Correo Electrónico"
                    type="text"
                    variant="standard"
                    helperText="Ingresa tu Correo Electrónico"
                    value={state.email}
                    onKeyPress={(event) => {
                      maxLengthLimitInput(event, 55, "email");
                    }}
                    onChange={handleEmail}
                  />
                </Grid>

              {/* {rolSelected === "Profesor" || rolSelected === "Admin" ? null : (
                <Grid item xs={6} lg={4}>
                  <TextField
                    fullWidth
                    margin="normal"
                    error={stateError.passError}
                    id="pass"
                    label="Contraseña Nueva"
                    type="password"
                    variant="standard"
                    helperText="Ingresa tu Contraseña"
                    value={state.pass}
                    onChange={handlePassword}
                  />
                </Grid>
              )} */}
              <Grid item xs={6} lg={4}>
                  <TextField
                    fullWidth
                    margin="normal"
                    error={stateError.passError}
                    id="pass"
                    label="Contraseña Nueva"
                    type="password"
                    variant="standard"
                    helperText="Ingresa tu Contraseña"
                    value={state.pass}
                    onChange={handlePassword}
                  />
                </Grid>

              {/* {rolSelected === "Profesor" || rolSelected === "Admin" ? null : (
                <Grid item xs={6} lg={4}>
                  <TextField
                    fullWidth
                    margin="normal"
                    error={stateError.confpassError}
                    id="confpass"
                    label="Confirma Contraseña"
                    type="password"
                    variant="standard"
                    helperText="Confirma tu Contraseña"
                    value={state.confpass}
                    onChange={handleConfirmPass}
                  />
                
                </Grid>
              )} */}
              <Grid item xs={6} lg={4}>
                  <TextField
                    fullWidth
                    margin="normal"
                    error={stateError.confpassError}
                    id="confpass"
                    label="Confirma Contraseña"
                    type="password"
                    variant="standard"
                    helperText="Confirma tu Contraseña"
                    value={state.confpass}
                    onChange={handleConfirmPass}
                  />
                
                </Grid>

              {localStorage.getItem("rol") === "Admin" && rol === "Profesor" ? (
                <Grid item xs={4} lg={6}>
                  <label>El profesor cuenta con...</label>
                  <div className="d-flex flex-column flex-lg-row  aling-content-center mt-4">
                    <div className="rb1 ">
                      <input
                        id="base"
                        type="radio"
                        checked={state.base === "base"}
                        onChange={() => setState({ ...state, base: "base" })}
                      />
                      <label for="base">Base</label>
                    </div>

                    <div className="rb-1">
                      <input
                        id="contrato"
                        type="radio"
                        checked={state.base === "contrato"}
                        onChange={() =>
                          setState({ ...state, base: "contrato" })
                        }
                      />{" "}
                      <label for="contrato">Contrato</label>
                    </div>
                  </div>
                </Grid>
              ) : null}
              <Grid item xs={5} md={6}>
                {editable ? null : (
                  <>
                    <label>Sexo</label>
                    <div className="d-flex flex-column flex-lg-row  aling-content-center mt-4">
                      <div className="rb1 ">
                        <input
                          id="M"
                          type="radio"
                          onChange={() => {
                            setState({ ...state, sexo: "M" });
                          }}
                          checked={state.sexo === "M"}
                        />
                        <label for="M">Femenino</label>
                      </div>

                      <div className="rb-1">
                        <input
                          id="H"
                          type="radio"
                          onChange={() => {
                            setState({ ...state, sexo: "H" });
                          }}
                          checked={state.sexo === "H"}
                        />{" "}
                        <label for="H">Masculino</label>
                      </div>
                    </div>
                  </>
                )}
              </Grid>

              <Grid item xs={4} md={6}>
                {editable ? (
                  <>
                    <label style={{}}>¿Cuentas con alguna Discapacidad?</label>
                    <div className="d-flex flex-column flex-lg-row  aling-content-center mt-4">
                      <div className="rb1 ">
                        <input
                          id="radio5"
                          type="radio"
                          checked={state.selected2 === "radio5"}
                          onChange={() => {
                            setState({ ...state, selected2: "radio5" });
                          }}
                        />
                        <label for="radio5">Si</label>
                      </div>

                      <div className="rb-1">
                        <input
                          id="radio6"
                          type="radio"
                          checked={state.selected2 === "radio6"}
                          onChange={() => {
                            setState({ ...state, selected2: "radio6" });
                          }}
                        />{" "}
                        <label for="radio6">No</label>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <label style={{}}>¿Cuentas con alguna Discapacidad?</label>
                    <div className="d-flex flex-column flex-lg-row  aling-content-center mt-4">
                      <div className="rb1 ">
                        <input
                          id="radio3"
                          type="radio"
                          checked={state.selected2 === "radio3"}
                          onChange={() => {
                            setState({ ...state, selected2: "radio3" });
                          }}
                        />
                        <label for="radio3">Si</label>
                      </div>

                      <div className="rb-1">
                        <input
                          id="radio4"
                          type="radio"
                          checked={state.selected2 === "radio4"}
                          onChange={() => {
                            setState({ ...state, selected2: "radio4" });
                          }}
                        />{" "}
                        <label for="radio4">No</label>
                      </div>
                    </div>
                  </>
                )}
              </Grid>
            </Grid>
          </div>
          <div>
            <div className="mt-5 d-flex justify-content-center align-items-center">
              <div
                className="cta cta--guinda cta--small"
                onClick={handleCrearCuenta}
              >
                {editable ? "Actualizar" : "Registrar"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormularioRegistro;
