import { useState, useEffect, useContext } from "react";
import { services } from "../services/api";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { GlobalContext } from "../context/global_context";
import InputLabel from "@mui/material/InputLabel";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import { NotificacionContext } from "../context/notificacion_context";

const InicioSesion = ({ login, setLogin }) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const loadData = async () => {
      setLogin(localStorage.getItem("token"));
      const timeoutId = setTimeout(() => {
        setState((prevState) => ({
          ...prevState,
          loading: false,
        }));
      }, 1500);
      return () => clearTimeout(timeoutId);
    };

    loadData();
  }, [location, navigate, localStorage]);

  const [state, setState] = useState({
    email: "",
    pass: "",
    error: "",
    showPassword: false,
    inscribiendo: false,
    loading: true,
  });

  const { GlobalState, setGlobalState } = useContext(GlobalContext);
  const { showNotification } = useContext(NotificacionContext);

  const handdleSubmit = async (e) => {
    e.preventDefault();
    const email = state.email;
    const pass = state.pass;

    if (email === "") {
      showNotification(
        "warning",
        "Todos los campos son requeridos",
        "Ingresa un Email",
        null,
        4
      );
    } else if (pass === "") {
      showNotification(
        "warning",
        "Todos los campos son requeridos",
        "Ingresa una Contraseña",
        null,
        4
      );
    } else if (pass === "") {
    } else {
      setState({ ...state, inscribiendo: true });

      const dataApiUser = await services(
        "POST",
        `login?email=${email}&password=${pass}`,
        null
      );

      if (dataApiUser) {
        await localStorage.setItem("token", dataApiUser.data.token);
        await localStorage.setItem("curp", dataApiUser.data.curp);
        await localStorage.setItem("id", dataApiUser.data.id);

        localStorage.setItem("rol", dataApiUser.data.rol);
        setLogin(dataApiUser.data.token);
        if (dataApiUser.data.rol === "Admin") {
          navigate("/admin");
        } else if (dataApiUser.data.rol === "Profesor") {
          navigate("/mis-talleres");
        } else if (dataApiUser.data.rol === "Alumno") {
          navigate("/mis-cursos");
        } else {
          if (localStorage.getItem("tallerSelected")) {
            inscribir(localStorage.getItem("tallerSelected"));
          } else {
            navigate("/");
          }
        }
      } else {
        setState({ ...state, inscribiendo: false });
        showNotification(
          "warning",
          "Su contraseña o correo email son incorrectos",
          "Ingresa una contraseña o email correctos",
          null,
          4
        );
      }
    }
  };

  const handlePassword = (e) => {
    setState({ ...state, pass: e.target.value });
  };

  const handleEmail = (e) => {
    setState({ ...state, email: e.target.value });
  };

  const handleClickShowPassword = () => {
    setState({ ...state, showPassword: !state.showPassword });
  };

  const inscribir = async (data) => {
    const dataIscripcion = await services(
      "GET",
      `usuarios/crearinscripcionalumno?taller=${data}`,
      null
    );
    if (dataIscripcion.status === 200) {
      if (dataIscripcion.data?.error) {
      } else {
        setGlobalState({
          ...GlobalState,
          lineaCaptura: dataIscripcion.data,
          openpagoLinea: true,
        });
        setState({ ...state, inscribiendo: false });
        navigate("/mis-cursos");
      }
    } else {
    }
  };

  return (
    <>
      {state.loading ? (
        <div style={{ height: "calc(100vh - 80px)" }}>
          <span className="loader"></span>
        </div>
      ) : (
      <div className="bg-fondo">
        {state.inscribiendo ? (
          <div style={{ height: "calc(100vh - 80px)" }}>
            <span className="loader"></span>
          </div>
        ) : (
          <div className="container " style={{ height: "calc(100vh - 80px)" }}>
            <div className="d-flex align-items-center justify-content-center h-100">
              <form onSubmit={handdleSubmit}>
                <div className="card">
                  <div className="my-3 text--morado text-bold borderBottomTitulo">
                    Inicia Sesión
                  </div>

                  <FormControl sx={{ width: "100%" }} variant="standard">
                    <InputLabel htmlFor="standard-adornment-password">
                      Email
                    </InputLabel>
                    <Input
                      onChange={handleEmail}
                      variant="outlined"
                      id="standard-adornment-email"
                      type={"text"}
                    />
                  </FormControl>

                  <FormControl
                    sx={{ marginTop: 2, width: "100%" }}
                    variant="standard"
                  >
                    <InputLabel htmlFor="standard-adornment-password">
                      Password
                    </InputLabel>
                    <Input
                      variant="outlined"
                      onChange={handlePassword}
                      id="standard-adornment-password"
                      type={state.showPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                          >
                            {state.showPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                  <div className="mt-3">
                    <button className="cta cta--guinda" type="submit">
                      Entrar
                    </button>
                    <div
                      className="cta cta--guinda mt-2  "
                      onClick={() => navigate("/crearCuenta")}
                    >
                      Crear Cuenta
                    </div>
                    <p style={{ marginTop: "5%" }}>
                      ¿Olvidaste tu contraseña? click{" "}
                      <Link to={"/reset/password"}>aqui</Link>
                    </p>
                  </div>

                  <div
                    className="text-center mt-3"
                    style={{ height: "30px", color: "red" }}
                  >
                    {state.error}
                  </div>

                  <div></div>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
      )}
    </>
  );
};

export default InicioSesion;
