import { Box, Container, Grid, Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { styled } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import { services } from '../services/api';
import PaymentIcon from '@mui/icons-material/Payment';




const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText('#005cbf'),
    backgroundColor: '#606770',
    '&:hover': {
        backgroundColor: '#606770',
    },
}));

const ColorButtonSecond = styled(Button)(({ theme }) => ({
    color: '#606770',
    backgroundColor: '#ffc3d0',
    border: 'none',
    '&:hover': {
        transform: 'scale(1.005)',
        boxShadow: '3px 3px 6px 0px rgba(0, 0, 0, 0.2)',
    },
}));

const PagoLineaPublic = () => {
    let {search}  = useLocation();
    let code = new URLSearchParams(search);
    let lcaptura = code.get('lcaptura');
    
    const [state, setState] = useState({
        CONCEPTOPLINEA: "",
        FECHAPLINEA: "",
        FORMATO: "",
        IMPORTE: "",
        LINEA_CAPTURA: "",
        ESTATUS:"",
        error:true
    })

    const consultaInfo = async () =>{
        let consultaApi = await services('GET', `usuarios/validaLcaptura/${lcaptura}`)
        setState({...state, ...consultaApi.data })
    }

    useEffect(() => {
        let isMounted = true
        if (isMounted) {
            consultaInfo();
        }
    
        return () => {
          isMounted = false
        }
        // eslint-disable-next-line
      }, [])

    const pdfFake = '';

    return (
        <div className=' h-100'>
            <Container maxWidth="md">
                <Box sx={{ height: 'auto', paddingTop: '6%' }}>
                    <Box >
                        <Grid container spacing={1} sx={{ justifyContent: 'center', alignItems: 'center' }}>
                            <Grid item xs="auto">
                                <a rel="noreferrer" target='_blank' href={state.FORMATO ? `${state.FORMATO}/2` : pdfFake}>
                                    <ColorButtonSecond variant="outlined" endIcon={<CloudDownloadIcon />} > 
                                        {state.ESTATUS === "pendiente" ? "Descargar mi formato de pago" : "Descargar mi orden de pago"}
                                    </ColorButtonSecond>
                                </a>
                            </Grid>
                            <Grid item xs="auto">
                                <form action={process.env.REACT_APP_URLPAGO} method="post">

                                    <input type="hidden" name="concepto" value={state.CONCEPTOPLINEA} />
                                    <input type="hidden" name="lcaptura" value={state.LINEA_CAPTURA} />
                                    <input type="hidden" name="monto" value={state.IMPORTE} />

                                    <input type="hidden" name="fecha_vencimiento" value={state.FECHAPLINEA} />
                                    <input type="hidden" name="tkn" value={state.TOKEN} />
                                    <input type="hidden" name="urlRetorno" value={process.env.REACT_APP_URLREDIRECT} />
                                    <input type="hidden" name="email" value="" />

                                    {state.ESTATUS === "pendiente" &&
                                        <ColorButton variant="outlined"
                                            type='submit'
                                            endIcon={<PaymentIcon />} >   Pagar en Línea</ColorButton>
                                    }
                                </form>
                            </Grid>

                        </Grid>
                    </Box>
                </Box>
                <div className='pt-2 border-bottom--guinda text-big mb-2 text-bold'>
                    {state.ESTATUS === "pendiente" ? "Formato de pago" : "Orden de Pago"}
                </div>
                {!state.error ?
                    <div className='d-flex justify-content-center aling-items-center pt-3 '>
                    </div>
                    :
                    <div className='vh-100 d-flex justify-content-center aling-items-center pt-3 pb-3'>
                        <object
                            data={state.FORMATO}
                            type="application/pdf"
                            height='100%'
                            width='100%'
                        >
                        </object>
                    </div>
                }
            </Container>

        </div>
    )
}

export default PagoLineaPublic