import React, { useState, useContext  } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import { services } from '../services/api';
import LoaderComponent from './LoaderComponent';
import { NotificacionContext } from '../context/notificacion_context';

const ResetPassword = () => {

    let { password } = useParams();
    const navigate = useNavigate();
    // console.log(password)


    const [state, setState] = useState({
        mensaje: 'Por favor, rellene el correo electrónico que utilizó para registrarse. Se le enviará un correo electrónico con instrucciones sobre cómo restablecer su contraseña.',
        email: null,
        envioCorreo:false,
        error:false,
        cambioContraseña: false,
        mensajeCambioPassword:'',
        showPassword:false,
        setpassword:"",
        setConfirmpassword:"",
        iralogin:true,
    })

    const { showNotification } = useContext(NotificacionContext)
    
    const [loader, setLoader ]  = useState(false)

    const handdleRecuperarpassword = () => {

        if(state.email){

            services('POST','usuarios/password-forget',{email:state.email}).then((response) =>{

                if(response){
                   limpiar(response.data.error, response.data.create);
                }
    
            }).finally(()=>{
                setLoader(false)
            });

        }
    }

    const handleEmail = (e) => {
        setState({ ...state, email: e.target.value });
    }

    const limpiar = (mensaje,typo,login) =>{

    setState({ ...state,  mensaje: mensaje , email: '', envioCorreo:true, error:!typo, iralogin:login});

    }

    const handdleCambiarPassword = async () =>{
                
        if(state.setpassword.length <=6 || state.setConfirmpassword.length <=6){
            showNotification('error', 'Tu contraseña debe tener minimo 6 caracteres', null, 4);
            return;
        }else{
            
                        services('POST','usuarios/password-change',{password:state.setpassword ,password_confirmation:state.setConfirmpassword, code:password})
                        .then((response) =>{
            
                          
                            if(response){
                              limpiar(response.data.mensaje, response.data.update,response.data.update);
                            }
                
                        }).finally(()=>{
                            setLoader(false)
                        });

        }
           
                
                
            
           
               
                
            
                

    }

    const handlePassword = (e) => {
        setState({ ...state, setpassword: e.target.value });
    }
    const handleConfirmPassword = (e) => {
        setState({ ...state, setConfirmpassword: e.target.value });
    }

    const handleClickShowPassword = ()=>{
        setState({ ...state, showPassword:!state.showPassword});
    }


    return (

        <>
        {loader &&
            <LoaderComponent />
        }

          

                <div className="container" style={{ height: 'calc(100vh - 80px)' }}>
                    <div className='d-flex align-items-center justify-content-center h-100'>
                        <div className='card'>

                        {password === 'password' ?
                            <>
                                <div className='my-3 text--morado text-bold borderBottomTitulo'>¿Olvidaste tu contraseña?</div>

                                <div className="mt-3" style={{ textAlign: 'justify', maxWidth:'330px', color: state.envioCorreo ?  state.error ? 'red' : 'green' : 'black' }}>{state.mensaje}</div>
                                    <FormControl sx={{ m: 1, width: '35ch' }} variant="standard">
                                        <InputLabel htmlFor="standard-adornment-password">Email</InputLabel>
                                        <Input
                                            onChange={handleEmail}
                                            variant="outlined"
                                            value={state.email ? state.email : ''}
                                            id="standard-adornment-email"
                                            type={'text'}
                                        />
                                    </FormControl>
                                <div className='mt-3'>
                                    <div className='cta cta--guinda' onClick={() => {setLoader(true);handdleRecuperarpassword()}}>Enviar</div>
                                </div>
                            </>
                              :
                              <>
                              <div className='my-3 text--morado text-bold borderBottomTitulo'>Cambia tu contraseña</div>

                                <div className="mt-3" style={{ textAlign: 'justify', maxWidth:'330px', color: state.cambioContraseña ?  state.error ? 'red' : 'green' : 'black' }}>{state.mensajeCambioPassword}</div>
                                    
                                    <FormControl sx={{ m: 1, width: '25ch' }} variant="standard">
                                    <InputLabel htmlFor="standard-adornment-password">Nueva Contraseña</InputLabel>
                                    <Input
                                    variant="outlined"
                                    onChange={handlePassword}
                                    id="standard-adornment-password"
                                    type={state.showPassword ? 'text' : 'password'}
                                    endAdornment={
                                        <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                        >
                                            {state.showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                        </InputAdornment>
                                    }
                                    />
                                    </FormControl>
                                    <FormControl sx={{ m: 1, width: '25ch' }} variant="standard">
                                    <InputLabel htmlFor="standard-adornment-password">Confirmar Contraseña</InputLabel>
                                    <Input
                                    variant="outlined"
                                    onChange={handleConfirmPassword}
                                    id="standard-adornment-password"
                                    type={state.showPassword ? 'text' : 'password'}
                                    />
                                    </FormControl>

                                   
                                <div className='mt-3'>
                                {state.iralogin ? 
                                    <div className='cta cta--guinda' onClick={handdleCambiarPassword}>Enviar</div>
                                :
                                    <div className='cta cta--guinda' onClick={() =>{navigate('/login')}}>Ir a Login</div>
                                }
                                </div>
                               
                              
                              </>
                        }
                        </div>
                    </div>
                </div>
              
        </>);
}

export default ResetPassword;