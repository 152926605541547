import { createContext, useState } from "react";

export const GlobalContext = createContext();

export const GlobalContextProvider = ({ children }) => {
  const [GlobalState, setGlobalState] = useState({
    userLoggin: "false",
    path: "",
    navBarOpen: false,
    vista: "Registro Usuarios",
    noticiasComponent: false,
    modalAlumno: false,
    buscar: false,
    rolInscripcion: "Alumno",
    formatoDetalle:
      "dHlPZzF6Y2Z5SnNBVjhaZlZuK0graTNtYXQ2TXZ5WmwzRkF2QmxNNFZQMD0=",
    lineaCaptura: {
      CONCEPTOPLINEA: "Inscripcion Casa Cultura Morelia",
      FECHAPLINEA: "31/03/2023",
      FORMATO:
        "http://10.8.7.179/casa-cultura-back/public/pdfDIversos/eTRaRGxrVXlaMVA3bGZHbDZHSDR3NHI4cHUvZzMzQ3RibEF0WnBwcWZzUT0=",
      IMPORTE: "345.00",
      LINEA_CAPTURA: "30008151825338122288",
      TOKEN:
        "UHEvRUxUenpxV3kxZ1EvNDVCd1FFazhtSERpZFkxNGNjWVY1YXlVSUFQU0pVR3BNV2dUNEYrTUVVYnVpZHVDVG9JWnFZRCtwVGlMOE1wNGQ5NHFlN0s1RWhwYWZ0UnFRUFdsMFpxeWxYTVU9",
    },
    openpagoLinea: false,
  });

  return (
    <GlobalContext.Provider
      value={{
        GlobalState,
        setGlobalState,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
