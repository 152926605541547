import React, { useState, useContext, useEffect } from 'react'
import DataTable from './DataTableComponent';
import Grid from '@mui/material/Grid';
import { AiOutlineEdit } from 'react-icons/ai'
import { styled } from '@mui/material/styles';
import Modal from '@mui/material/Modal';
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { services } from '../services/api'
import Button from '@mui/material/Button';
import { NotificacionContext } from '../context/notificacion_context';
import IconUI from './ui/icon_ui';
import { Box, FormHelperText, TextareaAutosize, Tooltip } from '@mui/material';
import SchoolIcon from '@mui/icons-material/School';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import LoaderComponent from './LoaderComponent';


const styleModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '85%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    mb: 2,
    display: "flex",
    flexDirection: "column",
    height: 600,
    overflow: "hidden",
    overflowY: "scroll",
};

const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText('#005cbf'),
    backgroundColor: '#4A001F',
    '&:hover': {
        backgroundColor: '#4A001F',
    },
}));


const Talleres = () => {


    const [state, setState] = useState({
        open: false,
        data: [],
        diciplinas: [],
        periodos: [],
        talleres: null,
        imagenesTaller: [],
        nombreTaller: '',
        horarioinicio: '',
        descripcion: '',
        rangoEdad: '',
        nivel: '',
        diciplina: '',
        Salon: '',
        idEdit: null,
        imagenesName: null,
        nombreError: false,
        HinicioError: false,
        descripcionError: false,
        rangoError: false,
        nivelError: false,
        diciplinaError: false,
        salonError: false,
    });

    const [loader, setLoader] = useState(false)

    const { showNotification } = useContext(NotificacionContext)

    const openmodal = () => {
        setState({ ...state, open: true });
    }
    const handleClose = () => {
        setState({
            ...state,
            open: false,
            nombreTaller: '',
            horarioinicio: '',
            horariofinal: '',
            descripcion: '',
            rangoEdad: '',
            nivel: '',
            diciplina: '',
            idEdit: null,
            imagenesName: null,
            imagenesTaller: [],
            nombreError: false,
            HinicioError: false,
            HfinalError: false,
            descripcionError: false,
            rangoError: false,
            nivelError: false,
            diciplinaError: false,
        });
        setFotos([])
        setFotosPrew([])
    }


    const validar = () => {

        const nombreTaller = state.nombreTaller === '';
        const horarioinicio = state.horarioinicio === '';
        const descripcion = state.descripcion === '';
        const rangoEdad = state.rangoEdad === '';
        const nivel = state.nivel === '';
        const diciplina = state.diciplina === '';

        setState({
            ...state,
            nombreError: nombreTaller,
            HinicioError: horarioinicio,
            descripcionError: descripcion,
            rangoError: rangoEdad,
            nivelError: nivel,
            diciplinaError: diciplina,
        });
        return !nombreTaller && !horarioinicio && !descripcion && !rangoEdad && !nivel && !diciplina;
    }

    const subirTaller = (type) => {

        let url = 'usuarios/creartalleres';

        if (type === 'edit') {

            url = `usuarios/editartalleres/${state.idEdit}`;
        }

        if (validar()) {
            setLoader(true)

            const body = new FormData();
            body.append("nombre", state.nombreTaller);
            body.append("horariotaller", state.horarioinicio);
            body.append("url", 'http://');
            body.append("rango", state.rangoEdad);
            body.append("nivel", state.nivel);
            body.append("descripcion", state.descripcion);
            body.append("id_disciplina", state.diciplina);
            state.imagenesTaller.forEach(item => {
                body.append("fotos[]", item);
            });


            services('POST', url, body).then((response) => {
                if (response.status === 200) {

                    if (type === 'edit') {

                        if (response.data.editar) {
                            showNotification('success', 'Taller Editado', response.data.mensaje, null, 4);

                            handleClose();
                            setLoader(false)
                            api()
                        } else {
                            showNotification('error', 'Error Al Actualizar', response.data.error, null, 4);
                            setLoader(false)
                        }

                    } else {
                        if (response.data.created) {
                            showNotification('success', 'Taller creado', response.data.mensaje, null, 4);

                            const apiUpdate = async () => {
                                let talleresUpdate = await services('GET', 'usuarios/listartalleres', null);
                                setState({ ...state, talleres: talleresUpdate.data, open: false });
                            }
                            apiUpdate();
                            setLoader(false);
                        } else {
                            showNotification('warning', 'Atencion', response.data.mensaje, null, 4);
                            setLoader(false)
                        }
                    }

                }
                if (response.status !== 200) {

                    showNotification('error', 'Atencion', response.errors, null, 4);
                    setLoader(false)
                }
            }).catch(err => {

            })
        } else {
            showNotification('warning', 'Todos los campos son requeridos', 'e intente de nuevo', null, 4);
        }
    }

    // const eliminarTaller = (id) => {
    //     setLoader(true)
    //     services('POST', `usuarios/eliminartalleres/${id}`, null).then((response) => {
    //         if (response.status === 200 && response.data.delete) {
    //             showNotification('success', 'Taller Eliminado', 'El taller fue eliminado', null, 4);
    //             setState({
    //                 ...state,
    //                 talleres: state.talleres.filter(item => item.id !== id)
    //             })
    //             setLoader(false)

    //         } else {
    //             showNotification('error', response.data.mensaje, null, 4);
    //             setLoader(false)
    //         }
    //     })
    // }

    const [fotos, setFotos] = useState([])
    const editar = async (item) => {

        let disciplinaID = state.diciplinas.filter(element => element.Nombre === item.disciplina)
        // console.log(item, disciplinaID)

        setState({
            ...state,
            open: true,
            idEdit: item.id,
            nombreTaller: item.nombretaller,
            horarioinicio: item.horariotaller,
            descripcion: item.descripcion,
            rangoEdad: item.rango,
            nivel: item.nivel,
            diciplina: disciplinaID[0].id,
        })

        await services('GET', `usuarios/obtener-taller/${item.id}`, null)
            .then(response => {
                setFotos(response.data.data.fotos)

            }).catch(error => {
                // console.log(error)
            })
    }

    const handleNombre = (e) => {
        setState({ ...state, nombreTaller: e.target.value });
    }
    const handlehorarioinicio = (e) => {
        setState({ ...state, horarioinicio: e.target.value });
    }

    const handledescripcion = (e) => {
        setState({ ...state, descripcion: e.target.value });
    }
    const handlerangoEdad = (e) => {
        setState({ ...state, rangoEdad: e.target.value });
    }
    const handlenivel = (e) => {
        setState({ ...state, nivel: e.target.value });
    }
    const handlediciplina = (e) => {
        setState({ ...state, diciplina: e.target.value });
    }
    const guardarimagens = (e) => {

        let img = e.target.files;
        let imagenes = [];
        let names = [];
        let namesstring = '';
        let preview = []
        for (let index = 0; index < img.length; index++) {
            const element = img[index];
            names.push(element.name);
            imagenes.push(img[index]);
            preview.push(URL.createObjectURL(img[index]))
        }
        // eslint-disable-next-line
        names.map(item => { namesstring = namesstring + item + ', ' });
        setFotosPrew(preview)

        setState({ ...state, imagenesTaller: imagenes, imagenesName: namesstring });
    }

    const [fotosPrew, setFotosPrew] = useState([])

    const api = async () => {
        if (state.talleres !== null) setLoader(true)
        const diciplinas = await services('GET', 'usuarios/listardisciplinas', null);
        const periodo = await services('GET', 'usuarios/listarperiodos', null);
        const talleres = await services('GET', 'usuarios/listartalleres', null);
        // setState({ ...state,     });
        setState({
            ...state,
            open: false,
            diciplinas: diciplinas.data,
            periodos: periodo.data,
            talleres: talleres.data ? talleres.data : [],
            nombreTaller: '',
            horarioinicio: '',
            horariofinal: '',
            descripcion: '',
            rangoEdad: '',
            nivel: '',
            diciplina: '',
            idEdit: null,
            imagenesName: null,
            imagenesTaller: [],
            nombreError: false,
            HinicioError: false,
            HfinalError: false,
            descripcionError: false,
            rangoError: false,
            nivelError: false,
            diciplinaError: false,
        })
        setLoader(false)
    }


    const columns = [
        {
            name: "nombretaller",
            label: "Titulo",
            align: "center",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex, i) => {
                    let nombre = state.talleres[dataIndex].nombretaller;
                    return (<div style={{ height: '60px' }} className={i % 2 === 0 ? "bg-rosaClaro d-flex align-items-center justify-content-center" : "d-flex align-items-center justify-content-center"}>{nombre}</div>)
                }
            }
        },
        {
            name: "descripcion",
            label: "Descripción",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex, i) => {
                    let Descripcion = state.talleres[dataIndex].descripcion;
                    return (<div style={{ height: '100px', overflowY: "scroll" }} className={i % 2 === 0 ? "bg-rosaClaro d-flex align-items-center justify-content-center" : "d-flex align-items-center justify-content-center"}><span style={{ height: "inherit" }}>{Descripcion}</span></div>)
                }
            }
        },
        {
            name: "horariotaller",
            label: "Horario",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex, i) => {
                    let imagen = state.talleres[dataIndex].horariotaller;
                    return (<div style={{ height: '60px' }} className={i % 2 === 0 ? "bg-rosaClaro d-flex align-items-center justify-content-center" : "d-flex align-items-center justify-content-center"}>{imagen}</div>)
                }
            }
        },
        {
            name: "rango",
            label: "Rango Edades",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex, i) => {
                    let creado = state.talleres[dataIndex].rango;
                    return (<div style={{ height: '60px' }} className={i % 2 === 0 ? "bg-rosaClaro d-flex align-items-center justify-content-center" : "d-flex align-items-center justify-content-center"}>{creado}</div>)
                }
            }
        },
        {
            name: "nivel",
            label: "Nivel",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex, i) => {
                    let creado = state.talleres[dataIndex].nivel;
                    return (<div style={{ height: '60px' }} className={i % 2 === 0 ? "bg-rosaClaro d-flex align-items-center justify-content-center" : "d-flex align-items-center justify-content-center"}>{creado}</div>)
                }
            }
        },
        {
            name: "disciplina",
            label: "Disciplina",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex, i) => {
                    let creado = state.talleres[dataIndex].disciplina ? state.talleres[dataIndex].disciplina : '';
                    return (<div style={{ height: '60px' }} className={i % 2 === 0 ? "bg-rosaClaro d-flex align-items-center justify-content-center" : "d-flex align-items-center justify-content-center"}>{creado}</div>)
                }
            }
        },
        {
            name: "acciones",
            label: "Acciones",

            options: {
                filter: false,
                sort: false,
                download: false,
                customBodyRenderLite: (dataIndex, i) => (
                    <>
                        <div style={{ height: '60px' }} className={i % 2 === 0 ? "w-100 d-flex align-items-center justify-content-center bg-rosaClaro " : "w-100 d-flex justify-content-center align-items-center "}>
                            <Tooltip title="Editar Taller">
                                <div style={{ height: 30, width: 30, zIndex: 0, }} className='cta cta--primary cta--icon icon_btn' onClick={
                                    () => {

                                        editar(state.talleres[dataIndex]);
                                    }}>
                                    <IconUI size={'20px'} color={'black'}><AiOutlineEdit /></IconUI>

                                </div>
                            </Tooltip>

                        </div>
                    </>
                )
            }
        },
    ];

    useEffect(() => {
        let isMounted = true
        if (isMounted) {

            api();
        }

        return () => {
            isMounted = false
        }
        // eslint-disable-next-line
    }, [])


    return (
        <>
            {loader &&
                <LoaderComponent />
            }
            {state.talleres ?


                <div style={state.talleres.length > 3 ? {} : { height: 'calc(100vh - 80px)' }}>

                    <div className='container'>
                        <Grid sx={{ marginTop: 3 }} container spacing={2}>
                            <Grid align="center" item xs={12} sm={6} md={6}>
                            </Grid>
                            <Grid align="right" item xs={12} sm={6} md={6}>
                                <div style={{ width: 200, marginTop: "5%" }} className="cta cta--guinda" onClick={
                                    () => {
                                        openmodal();
                                    }
                                }><div className="me-2"><IconUI size={'20px'} color={'white'}><SchoolIcon /></IconUI></div>Nuevo Taller</div>
                            </Grid>
                        </Grid>
                    </div>
                    <DataTable
                        data={state.talleres}
                        title={'Talleres'}
                        columns={columns}
                        more={{
                            searchPlaceholder: "Buscar por Titulo"
                        }}
                    />

                    <Modal
                        open={state.open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={styleModal}>
                            <Grid item xs={12} sm={12} md={12}>
                                <h4>Agregar taller</h4>
                                <hr></hr>
                            </Grid>

                            <Grid container spacing={2} sx={{ justifyContent: 'center', alignItems: 'center' }}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <InputLabel id="label-titulo" style={{ paddingBottom: '5%' }} className="mx-2">Nombre</InputLabel>
                                    <TextField
                                        fullWidth
                                        error={state.nombreError}
                                        id="titulo"
                                        variant="standard"
                                        type='text'
                                        value={state.nombreTaller}
                                        helperText="Ingresa un Nombre para el taller"
                                        onChange={handleNombre}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ justifyContent: 'center', alignItems: 'center' }}>
                                <Grid item xs={12} sm={12} md={6}>
                                    <InputLabel id="label-titulo" style={{ paddingBottom: '10%', }} className="mx-2">Horario Taller</InputLabel>
                                    <TextField
                                        fullWidth
                                        error={state.HinicioError}
                                        id="titulo"
                                        variant="standard"
                                        type='text'
                                        value={state.horarioinicio}
                                        onChange={handlehorarioinicio}
                                        helperText="Ingresa un Horario"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <FormControl variant="standard" sx={{ m: 1, width: '100%', marginTop: '28px' }}>
                                        <InputLabel id="demo-simple-select-standard-label">Disciplina</InputLabel>
                                        <Select
                                            error={state.diciplinaError}
                                            fullWidth
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            onChange={handlediciplina}
                                            label="Disciplina"
                                            value={state.diciplina}
                                        >
                                            {state.diciplinas ? state.diciplinas.map((item) => (
                                                <MenuItem value={item.id}>{item.Nombre}</MenuItem>
                                            )) : <></>}
                                        </Select>
                                        <FormHelperText>Selecciona una Disciplina</FormHelperText>

                                    </FormControl>
                                </Grid>

                            </Grid>

                            <Grid container spacing={2} sx={{ justifyContent: 'center', alignItems: 'center' }}>
                                <Grid item xs={12} sm={12} md={6}>
                                    <InputLabel id="label-titulo" style={{ paddingBottom: '10%' }} className="mx-2">Rango de edades</InputLabel>
                                    <TextField
                                        fullWidth
                                        error={state.rangoError}
                                        id="titulo"
                                        variant="standard"
                                        type='text'
                                        value={state.rangoEdad}
                                        onChange={handlerangoEdad}
                                        helperText="Ingresa un Rango de Edad"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <InputLabel id="label-desc" style={{ paddingBottom: '10%' }} className="mx-2">Nivel</InputLabel>
                                    <TextField
                                        fullWidth
                                        id="desc"
                                        error={state.nivelError}
                                        variant="standard"
                                        type='text'
                                        multiline
                                        rows={1}
                                        rowsMax={10}
                                        value={state.nivel}
                                        onChange={handlenivel}
                                        helperText="Ingresa un Nivel"
                                    />
                                </Grid>
                            </Grid>
                            <Grid sx={{ marginTop: 1, marginBottom: 2 }} container spacing={2}>
                                <Grid style={{ width: '100%' }} item xs={12}>
                                    <InputLabel sx={{ marginBottom: 4 }} align="left" >Descripción</InputLabel>
                                    <FormControl style={{ width: '100%' }}>
                                        <TextareaAutosize
                                            placeholder="Ingresa una Descripcion"
                                            id="desc"
                                            error={state.descripcionError}
                                            value={state.descripcion}
                                            onChange={handledescripcion}
                                            style={{ width: '100%', paddingTop: 15, paddingLeft: 15, paddingRight: 15, paddingBottom: 15 }}

                                        />
                                    </FormControl>

                                </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ justifyContent: 'center', alignItems: 'center' }}>
                                <Grid item xs={10}>
                                    <TextField
                                        style={{ paddingTop: '5%', paddingBottom: '5%' }}
                                        variant="standard"
                                        fullWidth
                                        hiddenLabel
                                        disabled
                                        id="filled-hidden-label-normal"
                                        value={state.imagenesName ? state.imagenesName : "Imagen/s"}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={2}>
                                    <Button variant="contained" component="label">
                                        <FileUploadIcon />
                                        <input type="file" hidden
                                            multiple
                                            onChange={guardarimagens}
                                            accept='.png , .jpg' />
                                    </Button>
                                </Grid>
                                {fotos.length !== 0 &&
                                    <div className='d-flex flex-column'>
                                        <p>Imagenes Cargadas</p>
                                        <div className='d-flex flex-row flex-wrap'>
                                            {fotos.map(element => {
                                                return (
                                                    <img height={160} width={100} style={{ objectFit: 'contain' }} src={`data:application/${element.ext};base64,${element.img}`} />
                                                )
                                            })}

                                        </div>
                                    </div>
                                }
                                {fotosPrew.length !== 0 &&
                                    <div className='d-flex flex-column'>
                                        <div className='d-flex flex-row flex-wrap'>
                                            {fotosPrew.map(element => {
                                                console.log(element)
                                                return (
                                                    <img height={260} width={200} style={{ objectFit: 'contain' }} src={element} />
                                                )
                                            })}

                                        </div>
                                    </div>
                                }
                            </Grid>
                            <Grid container spacing={2} sx={{ justifyContent: 'center', alignItems: 'center', paddingTop: '4%' }}>
                                <Grid item xs="auto">
                                    <ColorButton variant="outlined"
                                        type='button'
                                        endIcon={
                                            <CloseIcon />
                                        }
                                        onClick={handleClose}>
                                        Cancelar
                                    </ColorButton>
                                </Grid>
                                <Grid item xs="auto">
                                    {state.idEdit ?
                                        <ColorButton variant="outlined"
                                            type='button'
                                            endIcon={
                                                <SaveIcon />
                                            }
                                            onClick={() => subirTaller('edit')}>
                                            Guardar
                                        </ColorButton>

                                        :

                                        <ColorButton variant="outlined"
                                            type='button'
                                            endIcon={
                                                <SaveIcon />
                                            }
                                            onClick={() => subirTaller('insert')}>
                                            Guardar
                                        </ColorButton>
                                    }

                                </Grid>
                            </Grid>
                        </Box>
                    </Modal>
                </div>
                : <div style={{ height: 'calc(100vh - 80px)' }}><span class="loader"></span></div>}
        </>);
}

export default Talleres;  