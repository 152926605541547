import React, { useState, useContext, useEffect } from "react";
import DataTable from "./DataTableComponent";
import Grid from "@mui/material/Grid";
import { AiOutlineUserAdd, AiOutlineEdit } from "react-icons/ai";
import Modal from "@mui/material/Modal";
import { services } from "../services/api";
import { NotificacionContext } from "../context/notificacion_context";
import IconUI from "./ui/icon_ui";
import { FcCancel } from "react-icons/fc";
import { GlobalContext } from "../context/global_context";
import FormularioRegistro from "./FormularioRegistoComponent";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import LoaderComponent from "./LoaderComponent";
import { Box, Tooltip } from "@mui/material";

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  width: "85%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  height: 550,
  overflow: "hidden",
  overflowY: "scroll",
};

const Usuarios = () => {
  const { setGlobalState, GlobalState } = useContext(GlobalContext);
  const [state, setState] = useState({
    data: null,
    rol: "Alumno",
    editable: false,
    usuarioSeleccionado: {},
  });

  const [loader, setLoader] = useState(false);

  const { showNotification } = useContext(NotificacionContext);

  const openmodal = async (valor, item) => {
    if (item === "addUser") {
      console.log(valor, "valor")
      setState({ ...state, editable: valor, usuarioSeleccionado: item });
      setGlobalState({
        ...GlobalState,
        modalAlumno: true,
        rolInscripcion: state.rol,
        buscar: true,
      });
    } else {
      await services("GET", `usuarios/obtener-usuario/${item.id}`).then(
        (response) => {
          if (response.status === 200) {
            setState({
              ...state,
              editable: valor,
              usuarioSeleccionado: response.data,
            });
            setGlobalState({
              ...GlobalState,
              modalAlumno: true,
              rolInscripcion: state.rol,
              buscar: true,
            });
          }
        }
      );
    }
  };

  const [rol, setRol] = useState("");

  const handleClose = () => {
    setGlobalState({ ...GlobalState, modalAlumno: false });
  };

  const handleRoles = (e) => {
    api(e.target.value === "" ? "Alumno" : e.target.value);
    setRol(e.target.value);
  };

  const handleEliminarUsuuario = async (data) => {
    const dataEliminarUsuario = await services(
      "POST",
      `usuarios/eliminarusuario/${data.id}`,
      null
    );

    if (dataEliminarUsuario.status === 200) {
      const dataPeriodos = await services(
        "GET",
        "usuarios/listarperiodos",
        null
      );
      setState({ ...state, periodos: dataPeriodos.data, open: false });
      showNotification("success", "Usuario Eliminado con Exito", "", null, 4);
      api(state.rol);
    } else if (dataEliminarUsuario.data?.created === false) {
      setState({ ...state, open: false });
      showNotification("error", dataEliminarUsuario.data?.error, "", null, 4);
    }
  };

  const api = async (typo) => {
    if (state.data !== null) {
      setLoader(true);
    }
    const data = await services("POST", "usuarios/listarusuarios", {
      tipo: typo,
    });
    if (data.status === 200) {
      setState({ ...state, data: data.data, rol: typo });
      setLoader(false);
    }
  };

  useEffect(() => {
    if (!GlobalState.buscar) {
      api(state.rol);
    }
    // eslint-disable-next-line
  }, [GlobalState.buscar]);

  useEffect(() => {
    if (GlobalState.modalAlumno === false) api(state.rol);

    // eslint-disable-next-line
  }, [GlobalState.modalAlumno]);

  const columns = [
    {
      name: "nombre",
      label: "Nombre",
      align: "center",
      options: {
        searchable: true,
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex, i) => {
          let Nombre = state.data[dataIndex].nombre;
          // console.log(state.data[dataIndex]);
          return (
            <div
              style={{ height: "60px" }}
              className={
                i % 2 === 0
                  ? "bg-rosaClaro d-flex align-items-center justify-content-center"
                  : "d-flex align-items-center justify-content-center"
              }
            >
              {Nombre}
            </div>
          );
        },
      },
    },
    {
      name: "apaterno",
      label: "Apellido paterno",
      options: {
        filter: false,
        searchable: true,
        sort: false,
        customBodyRenderLite: (dataIndex, i) => {
          let Descripcion = state.data[dataIndex].apaterno;
          return (
            <div
              style={{ height: "60px" }}
              className={
                i % 2 === 0
                  ? "bg-rosaClaro d-flex align-items-center justify-content-center"
                  : "d-flex align-items-center justify-content-center"
              }
            >
              {Descripcion}
            </div>
          );
        },
      },
    },
    {
      name: "amaterno",
      label: "Apellido materno",
      options: {
        filter: false,
        searchable: true,
        sort: false,
        customBodyRenderLite: (dataIndex, i) => {
          let Descripcion = state.data[dataIndex].amaterno;

          return (
            <div
              style={{ height: "60px" }}
              className={
                i % 2 === 0
                  ? "bg-rosaClaro d-flex align-items-center justify-content-center"
                  : "d-flex align-items-center justify-content-center"
              }
            >
              {Descripcion}
            </div>
          );
        },
      },
    },
    {
      name: "CURP",
      label: "CURP",
      options: {
        filter: false,
        searchable: true,
        sort: false,
        customBodyRenderLite: (dataIndex, i) => {
          let Descripcion = state.data[dataIndex].CURP;
          return (
            <div
              style={{ height: "60px" }}
              className={
                i % 2 === 0
                  ? "bg-rosaClaro d-flex align-items-center justify-content-center"
                  : "d-flex align-items-center justify-content-center"
              }
            >
              {Descripcion}
            </div>
          );
        },
      },
    },
    {
      name: "email",
      label: "Correo electrónico",
      options: {
        filter: false,
        searchable: true,
        sort: false,
        customBodyRenderLite: (dataIndex, i) => {
          let Descripcion = state.data[dataIndex].email;
          return (
            <div
              style={{ height: "60px" }}
              className={
                i % 2 === 0
                  ? "bg-rosaClaro d-flex align-items-center justify-content-center"
                  : "d-flex align-items-center justify-content-center"
              }
            >
              {Descripcion}
            </div>
          );
        },
      },
    },
    {
      name: "acciones",
      label: "Acciones",

      options: {
        filter: false,
        sort: false,
        download: false,
        customBodyRenderLite: (dataIndex, i) => (
          <>
            <div
              style={{ height: "60px" }}
              className={
                i % 2 === 0
                  ? "w-100 d-flex align-items-center justify-content-center bg-rosaClaro "
                  : "w-100 d-flex justify-content-center align-items-center "
              }
            >
              <Tooltip title="Editar usuario">
                <div
                  style={{ height: 30, width: 30, zIndex: 0 }}
                  className="cta cta--primary cta--icon icon_btn"
                  onClick={() => {
                    openmodal(true, state.data[dataIndex]);
                  }}
                >
                  <IconUI size={"20px"} color={"black"}>
                    <AiOutlineEdit />
                  </IconUI>
                </div>
              </Tooltip>
              <Tooltip title="Desactivar usuario">
                <div
                  style={{ height: 30, width: 30, zIndex: 0, marginLeft: 5 }}
                  className="cta cta--warning cta--icon icon_btn"
                  onClick={() => {
                    showNotification(
                      "option",
                      "Estás seguro de realizar esta acción?",
                      "Al dar click en Aceptar se eliminara al Usuario del sistema",
                      () => {
                        handleEliminarUsuuario(state.data[dataIndex]);
                      },
                      null
                    );
                  }}
                >
                  <IconUI size={"20px"} color={"black"}>
                    <FcCancel />
                  </IconUI>
                </div>
              </Tooltip>
            </div>
          </>
        ),
      },
    },
  ];

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      api("Alumno");
    }

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {loader && <LoaderComponent />}
      {state.data ? (
        <div
          style={state.data.length > 3 ? {} : { height: "calc(100vh - 80px)" }}
        >
          <div className="container">
            <Grid sx={{ marginTop: 3 }} container spacing={2}>
              <Grid className="mt-4" align="center" item xs={12} sm={6} md={6}>
                <FormControl
                  variant="standard"
                  sx={{ m: 1, minWidth: 200, marginTop: "5%" }}
                >
                  <InputLabel id="demo-simple-select-standard-label">
                    Tipo de Usuario
                  </InputLabel>
                  <Select
                    fullWidth
                    labelId="label-rol-usuario"
                    id="rol-usuario"
                    defaultValue={"Alumno"}
                    onChange={handleRoles}
                    label="Diciplina"
                  >
                    <MenuItem value="Alumno">Alumnos</MenuItem>
                    <MenuItem value={"Profesor"}>Profesores</MenuItem>
                    <MenuItem value={"Admin"}>Administradores</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid className="mt-4" align="center" item xs={12} sm={6} md={6}>
                <div
                  style={{ width: 200, marginTop: "5%" }}
                  className="cta cta--guinda"
                  onClick={() => {
                    openmodal(false, "addUser");
                  }}
                >
                  <div className="me-2">
                    <IconUI size={"20px"} color={"white"}>
                      <AiOutlineUserAdd />
                    </IconUI>
                  </div>
                  Nuevo {state.rol}
                </div>
              </Grid>
            </Grid>
          </div>
          <DataTable
            data={state.data}
            title={state.rol}
            columns={columns}
            more={{
              searchPlaceholder: "Buscar por Nombre",
            }}
          />

          <Modal
            open={GlobalState.modalAlumno}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={styleModal}>
              <FormularioRegistro
                rol={state.rol}
                rolSelected={rol}
                closeModal={handleClose}
                editable={state.editable}
                userSelected={state.usuarioSeleccionado.data}
              />
            </Box>
          </Modal>
        </div>
      ) : (
        <div style={{ height: "calc(100vh - 80px)" }}>
          <span class="loader"></span>
        </div>
      )}
    </>
  );
};

export default Usuarios;
