import React, { useEffect, useState, useContext } from "react";
import DataTable from "../componets/DataTableComponent";
import { NotificacionContext } from "../context/notificacion_context";
import Grid from "@mui/material/Grid";
import { services } from "../services/api";
import { useNavigate } from "react-router-dom";
import Collapse from "@mui/material/Collapse";
import Box from "@mui/material/Box";
import IconUI from "../componets/ui/icon_ui";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Tooltip } from "@mui/material";

export const ProfesoresView = () => {
  const [checked, setChecked] = React.useState(false);

  const handleChange = (id) => {
    setChecked(true);

    let arregloPendo = [];
    if (state.data[id]?.alumnos.length !== 0) {
      arregloPendo = state.data[id]?.alumnos.map((elem, index) => {
        return { id: index, nombre: elem };
      });
    }

    setState({ ...state, index: id, alumnos: arregloPendo });
  };

  const navigate = useNavigate();

  const [state, setState] = useState({
    data: [],
    talleres: [],
    alumnos: [],
    loading: true,
    index: 0,
  });

  const ListasProfesores = async () => {
    const profesores = await services("GET", `usuarios/showprofesor`, null);

    if (profesores.status === 200) {
      const onlyTaller = profesores.data?.map((elem) => {
        return elem.taller;
      });

      setState({ ...state, data: profesores.data, talleres: onlyTaller });
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (localStorage.getItem("rol") !== "Profesor") {
        navigate("/");
      } else {
        ListasProfesores();
      }
    }

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, []);

  // eslint-disable-next-line
  const { showNotification } = useContext(NotificacionContext);
  const columns = [
    {
      name: "nombretaller",
      label: "Taller",
      align: "center",
      options: {
        searchable: true,
        download: true,
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex, i) => {
          let nombre = `${state.data[dataIndex].taller.nombretaller}`;
          return (
            <div
              style={{ height: "60px" }}
              className={
                i % 2 === 0
                  ? "bg-rosaClaro d-flex align-items-center justify-content-center"
                  : "d-flex align-items-center justify-content-center"
              }
            >
              {nombre}
            </div>
          );
        },
      },
    },

    {
      name: "profesor",
      label: "Profesor",
      options: {
        download: true,
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex, i) => {
          let Descripcion = state.data[dataIndex].taller.profesor;
          return (
            <div
              style={{ height: "60px" }}
              className={
                i % 2 === 0
                  ? "bg-rosaClaro d-flex align-items-center justify-content-center"
                  : "d-flex align-items-center justify-content-center"
              }
            >
              {Descripcion}
            </div>
          );
        },
      },
    },

    {
      name: "acciones",
      label: "Acciones",

      options: {
        filter: false,
        sort: false,
        download: false,
        customBodyRenderLite: (dataIndex, i) => (
          <>
            <div
              style={{ height: "60px" }}
              className={
                i % 2 === 0
                  ? "w-100 d-flex align-items-center justify-content-center bg-rosaClaro "
                  : "w-100 d-flex justify-content-center align-items-center "
              }
            >
              <Tooltip title="Ver detalles">
                <div
                  style={{ height: 30, width: 30, zIndex: 0 }}
                  className="cta cta--primary cta--icon icon_btn"
                  onClick={() => {
                    handleChange(dataIndex);
                  }}
                >
                  <IconUI size={"20px"} color={"black"}>
                    <RemoveRedEyeIcon />
                  </IconUI>
                </div>
              </Tooltip>
            </div>
          </>
        ),
      },
    },
  ];

  const columnsAlumnos = [
    {
      name: "id",
      label: "N° Lista",
      options: {
        customBodyRenderLite: (index) => {
          return <p>{index + 1}</p>;
        },
      },
    },
    {
      name: "nombre",
      label: "Nombre Alumno",
      options: {
        customBodyRenderLite: (index) => {
          return <p>{state.data[state.index].alumnos[index]}</p>;
        },
      },
    },
  ];

  return (
    <>
      <div className="container my-4" style={{ height: "calc(100vh - 80px )" }}>
        <div className="container">
          <Grid sx={{ marginTop: 3 }} container spacing={2}>
            <Grid align="left" item xs={12} sm={12} md={12}>
              <h4>
                Hola <b>{state.data[0]?.taller.profesor}</b>!, Estos son tus
                talleres del trimestre
              </h4>
              <hr></hr>
            </Grid>
          </Grid>
        </div>
        <DataTable
          data={state.talleres}
          title={"Talleres"}
          columns={columns}
          more={{
            searchPlaceholder: "Buscar por Profesor",
          }}
        />

        <Box
          sx={{
            "& > :not(style)": {
              display: "flex",
              justifyContent: "space-around",
              height: "100%",
              width: "100%",
            },
          }}
        >
          {state.alumnos.length !== 0 && (
            <div>
              <Collapse in={checked} sx={{ width: "100%" }}>
                <DataTable
                  title={"Lista de alumnos"}
                  data={state.alumnos ?? []}
                  columns={columnsAlumnos}
                  more={{
                    searchPlaceholder: "Buscar por Nombre",
                  }}
                />
              </Collapse>
            </div>
          )}
        </Box>
      </div>
    </>
  );
};

export default ProfesoresView;
