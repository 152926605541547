import React, { useEffect, useState, useContext } from "react";
import Imagen from "../assets/images/LogoGuinda.png";
import { services } from "../services/api";
import Grid from "@mui/material/Grid";
import DataTable from "../componets/DataTableComponent";
import IconUI from "../componets/ui/icon_ui";
import { NotificacionContext } from "../context/notificacion_context";
import { FcCancel } from "react-icons/fc";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import { useNavigate } from "react-router-dom";
import PagoLinea from "../componets/PagoEnLinea";
import { GlobalContext } from "../context/global_context";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, Checkbox, FormControlLabel, Modal } from "@mui/material";
import Button from "@mui/material/Button";

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "75%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  height: "auto",
  p: 4,
};

const UsuariosView = () => {
  const [state, setState] = useState({
    talleres: [],
    info: [],
    loading: true,
    openRadio: false,
    lineaPago: {},
  });
  const { showNotification } = useContext(NotificacionContext);
  const navigate = useNavigate();

  const { GlobalState, setGlobalState } = useContext(GlobalContext);
  const pagarEnLinea = (taller) => {
    setGlobalState({
      ...GlobalState,
      openpagoLinea: true,
      lineaCaptura: taller,
    });
  };

  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const cancelFunction = async (dataIndex) => {
    let dataCancel = await services(
      "POST",
      `usuarios/cancelarcurso/${state.talleres[dataIndex].id}`,
      null
    );
    if (dataCancel.status === 200) {
      if (dataCancel.data?.error) {
        showNotification("warning", "Alerta!", dataCancel.data.error, null, 4);
      } else {
        showNotification(
          "success",
          "Acción Exitosa!",
          "El alumno ha cancelado su inscripción correctamente.",
          null,
          4
        );

        const apiUpdateInscriptions = async () => {
          let alumnosInscritosUpdated = await services(
            "GET",
            "usuarios/miscursos",
            null
          );
          setState({
            ...state,
            talleres: alumnosInscritosUpdated.data[0].talleres,
          });
        };
        apiUpdateInscriptions();
      }
    } else {
      showNotification("error", "Algo salio mal", dataCancel.error, null, 4);
    }
  };

  const columns = [
    {
      name: "Taller",
      label: "Taller",
      align: "center",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex, i) => {
          let nombre = `${state.talleres[dataIndex].nombretaller}`;
          return (
            <div
              style={{ height: "60px", minWidth:"60%"}}
              className={
                i % 2 === 0
                  ? "d-flex align-items-center justify-content-center"
                  : "d-flex align-items-center justify-content-center"
              }
            >
              {nombre}
            </div>
          );
        },
      },
    },
    {
      name: "Nivel",
      label: "Nivel",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex, i) => {
          let Descripcion = state.talleres[dataIndex].nivel;
          return (
            <div
              style={{ height: "60px", }}
              className={
                i % 2 === 0
                  ? "d-flex align-items-center justify-content-center"
                  : "d-flex align-items-center justify-content-center"
              }
            >
              {Descripcion}
            </div>
          );
        },
      },
    },
    {
      name: "Fecha-Limite-de-pago",
      label: "Fecha Limite de pago",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex, i) => {
          let imagen = state.talleres[dataIndex].fechavencimientotaller;
          return (
            <div
              style={{ height: "60px", minWidth:"100px"}}
              className={
                i % 2 === 0
                  ? "d-flex align-items-center justify-content-center"
                  : "d-flex align-items-center justify-content-center"
              }
            >
              {imagen}
            </div>
          );
        },
      },
    },
    {
      name: "status-pago",
      label: "Estado de Pago",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex, i) => {
          let imagen = state.talleres[dataIndex].stastustaller;
          return (
            <div
              style={{ height: "60px" }}
              className={
                i % 2 === 0
                  ? "d-flex align-items-center justify-content-center"
                  : "d-flex align-items-center justify-content-center"
              }
            >
              {imagen}
            </div>
          );
        },
      },
    },
    {
      name: "acciones",
      label: "Acciones",

      options: {
        filter: false,
        sort: false,
        download: false,
        customBodyRenderLite: (dataIndex, i) => (
          <>
            <div
              style={{ height: "60px", minWidth:"60%" }}
              className={
                i % 2 === 0
                  ? "w-100 d-flex align-items-center justify-content-center "
                  : "w-100 d-flex justify-content-center align-items-center "
              }
            >
              <div
                style={{ height: 30, width: 30, zIndex: 0 }}
                className="cta cta--primary cta--icon icon_btn"
                onClick={() => {
                  handleModal(state.talleres[dataIndex]);
                }}
              >
                <IconUI size={"20px"} color={"black"}>
                  {state.talleres[dataIndex].stastustaller === "pendiente" ? (
                    <MonetizationOnIcon />
                  ) : (
                    <VisibilityIcon />
                  )}
                </IconUI>
              </div>
              {state.talleres[dataIndex].stastustaller === "cancelado" ||
              state.talleres[dataIndex].stastustaller === "pagado" ? (
                <></>
              ) : (
                <div
                  style={{ height: 30, width: 30, zIndex: 0, marginLeft: 5 }}
                  className="cta cta--warning cta--icon icon_btn"
                  onClick={() => {
                    showNotification(
                      "option",
                      "Estás seguro de realizar esta acción?",
                      "Al dar click en Continuar se cancelará tu inscripción",
                      () => {
                        if (state.talleres[dataIndex].id) {
                          cancelFunction(dataIndex);
                        } else {
                          showNotification(
                            "error",
                            "Algo salio mal",
                            "No se pudo acceder a la Base de datos, consulta al administrador.",
                            null,
                            4
                          );
                        }
                      },
                      null
                    );
                  }}
                >
                  <IconUI size={"20px"} color={"black"}>
                    <FcCancel />
                  </IconUI>
                </div>
              )}
            </div>
          </>
        ),
      },
    },
  ];

  const handleModal = (taller) => {
    if (taller.stastustaller === "pendiente") {
      setState({ ...state, openRadio: true, lineaPago: taller });
    } else {
      pagarEnLinea(taller);
    }
  };

  const handlePago = () => {
    pagarEnLinea(state.lineaPago);
    setState({ ...state, openRadio: false });
  };

  const api = async () => {
    const response = await services("GET", "usuarios/miscursos", null);

    if (response.status === 200) {
      if (response.data.length === 0) {
        setState({
          ...state,
          talleres: [],
          info: { alumno: [] },
          loading: false,
        });
      } else {
        setState({
          ...state,
          talleres: response.data[0].talleres,
          info: { alumno: response.data[0].alumno },
          loading: false,
        });
      }
    } else {
      showNotification(
        "error",
        "Hubo un error",
        response.data?.mensage ?? "",
        null,
        4
      );
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (localStorage.getItem("rol") !== "Alumno") {
        navigate("/");
      } else {
        api();
      }
    }

    setGlobalState({ ...GlobalState, openpagoLinea: false });
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {state.loading ? (
        <div style={{ height: "calc(100vh - 80px)" }}>
          <span className="loader"></span>
        </div>
      ) : GlobalState.openpagoLinea ? (
        <PagoLinea />
      ) : (
        <div style={{ height: "calc(100vh - 80px )", width:"100%"}}>
          {state.talleres.length < 1 ? (
            <div style={{ height: "calc(100vh - 80px )", paddingTop: "2rem" }}>
              <div
                className="d-flex justify-content-center align-items-center mt-3 mx-3"
              >
                <h3>
                  No tienes talleres aún, selecciona uno en el apartado de Talleres
                </h3> 
              </div>
              <div className="d-flex justify-content-center align-items-center mt-3">
                <img className="imgUsuarioView" src={Imagen} alt="" />
              </div>
            </div>
          ) : (
            <>
              <div className="container mt-5">
                <Grid container spacing={2}>
                  <Grid align="center" item xs={12} sm={12} md={12}>
                    <h4>
                      Hola <b>{state.info.alumno}</b>!, Estos son tus talleres
                      del trimestre
                    </h4>
                    <hr></hr>
                  </Grid>
                </Grid>
              </div>

              <div className="table-container">
                <DataTable
                  data={state.talleres}
                  title={"Talleres"}
                  columns={columns}
                />
              </div>
            </>
          )}
        </div>
      )}
      <Modal
        open={state.openRadio}
        onClose={() => setState({ ...state, openRadio: false })}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal} className="modal-content">
          <Grid
            container
            spacing={2}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "2%",
            }}
          >
            <h4 style={{ textAlign: "center" }}>Aviso de Privacidad</h4>

            <span
              id="label-titulo"
              style={{ textAlign: "justify", height: "100%", overflow: "auto" }}
            >
              Tu privacidad es importante para nosotros. Como parte de nuestro
              compromiso de proteger tus datos personales, te instamos a leer nuestro
              aviso de privacidad. En él, encontrarás información detallada sobre
              cómo recopilamos, utilizamos y protegemos la información que nos
              proporcionas. Puedes encontrar nuestro aviso de privacidad en el
              siguiente enlace: {" "}
              <a
                target="_blank"
                href={process.env.PUBLIC_URL + "/AVISO_PRIVACIDAD.pdf"}
                rel="noreferrer"
              >
                Aviso de Privacidad
              </a>
              . Al utilizar nuestros servicios, confirmas que has leído y entendido
              nuestra política de privacidad y aceptas los términos establecidos en
              ella.
            </span>
            <FormControlLabel
              style={{
                margin: 0,
                position: "inherit",
                transformOrigin: "none",
                transform: "none",
              }}
              label="He leido el Aviso de privacidad"
              control={
                <Checkbox
                  checked={checked}
                  onChange={handleChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
            />
            <Button
              onClick={handlePago}
              className={`${!checked ? "cta--guinda_disabled" : "cta--guinda"}`}
              variant="contained"
              disabled={!checked}
            >
              Continuar
            </Button>
          </Grid>

          <Grid
            container
            spacing={6}
            sx={{ justifyContent: "center", alignItems: "center" }}
          >
            <Grid
              item
              xs={6}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            ></Grid>
          </Grid>
        </Box>
      </Modal>

    </>
  );
};

export default UsuariosView;
