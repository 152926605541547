import React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";

const DarkBackdrop = styled(Backdrop)(({ theme }) => ({
  zIndex: 100001,
  backgroundColor: "rgba(0, 0, 0, 0.7)", 
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
}));

const LoaderComponent = () => (
  <DarkBackdrop open={true}>
    <CircularProgress color="inherit" />
  </DarkBackdrop>
);

export default LoaderComponent;
