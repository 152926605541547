import React, { useContext } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { GlobalContext } from "../context/global_context";
import { useNavigate } from "react-router-dom";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import SchoolIcon from "@mui/icons-material/School";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PanoramaIcon from "@mui/icons-material/Panorama";
import LogoutIcon from "@mui/icons-material/Logout";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";

const NavBar = () => {
  const navigate = useNavigate();
  const { GlobalState, setGlobalState } = useContext(GlobalContext);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setGlobalState({ ...GlobalState, navBarOpen: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" ? "auto" : 250 }}
      role="presentation"
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItem key={"Admin"} disablePadding>
          <ListItemButton
            onClick={() => {
              navigate("/admin");
            }}
          >
            <ListItemIcon>
              <SupervisorAccountIcon />
            </ListItemIcon>
            <ListItemText primary={"Administrador"} />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <List>
        {[
          "Slider",
          "Noticias",
          "Registro Usuarios",
          "Disciplinas y Periodos",
          "Talleres",
          "TPP",
          "Inscripciones",
          "Reporte",
        ].map((text, index) => (
          <>
            {index === 7 ? (
              <>
                <Divider />
              </>
            ) : (
              <></>
            )}
            <ListItem key={text} disablePadding>
              <ListItemButton
                onClick={() => {
                  navigate("/admin");
                  setGlobalState({
                    ...GlobalState,
                    vista: text,
                    navBarOpen: !GlobalState.navBarOpen,
                  });
                }}
              >
                <ListItemIcon>
                  {index === 0 ? (
                    <PanoramaIcon />
                  ) : index === 1 ? (
                    <NewspaperIcon />
                  ) : index === 2 ? (
                    <InsertEmoticonIcon />
                  ) : index === 3 ? (
                    <InsertInvitationIcon />
                  ) : index === 4 ? (
                    <SchoolIcon />
                  ) : index === 6 ? (
                    <PersonAddIcon />
                  ) : index === 7 ? (
                    <LibraryBooksIcon />
                  ) : (
                    <SchoolIcon />
                  )}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          </>
        ))}
      </List>
      <Divider />
      <ListItem className="cerrarSesion" key={"Admin"} disablePadding>
        <ListItemButton
          onClick={() => {
            navigate("/login");
            localStorage.clear();
            setGlobalState({
              ...GlobalState,
              navBarOpen: !GlobalState.navBarOpen,
            });
          }}
        >
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary={"CERRAR SESIÓN"} />
        </ListItemButton>
      </ListItem>
    </Box>
  );

  return (
    <div>
      <Drawer
        anchor={"left"}
        open={GlobalState.navBarOpen}
        onClose={toggleDrawer(false)}
      >
        {list("left")}
      </Drawer>
    </div>
  );
};

export default NavBar;
