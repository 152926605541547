import React, { useState, useEffect, useContext } from "react";
import DataTable from "./DataTableComponent";
import Grid from "@mui/material/Grid";
import { AiOutlineUserAdd } from "react-icons/ai";
import { styled } from "@mui/material/styles";
import Modal from "@mui/material/Modal";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import { services } from "../services/api";
import Button from "@mui/material/Button";
import { NotificacionContext } from "../context/notificacion_context";
import IconUI from "./ui/icon_ui";
import { FcCancel } from "react-icons/fc";
import { Box, Tooltip } from "@mui/material";
import Select from "react-select";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
// import Swal from 'sweetalert2';
import { GlobalContext } from "../context/global_context";
import SearchIcon from "@mui/icons-material/Search";
import LoaderComponent from "./LoaderComponent";

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "95%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  mb: 2,
  display: "flex",
  flexDirection: "column",
  height: 550,
  overflow: "hidden",
  overflowY: "scroll",
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  mb: 2,
  display: "flex",
  flexDirection: "column",
  height: "90vh",
  overflow: "hidden",
  overflowY: "scroll",
};

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText("#005cbf"),
  backgroundColor: "#4A001F",
  "&:hover": {
    backgroundColor: "#4A001F",
  },
}));

const Inscripciones = () => {
  const [state, setState] = useState({
    alumnos: [],
    loadingAlumnos: true,
    loadingTalleres: true,
    open: false,
    openCaptura: false,
    alumnoSelect: null,
    TallerSelect: null,
    errorAlumnoS: false,
    errorTallerS: false,
    talleres: [],
    data: [],
    alumnosInscritos: [],
    talleresAlumnosInscritos: [],
    alumnoActual: "",
    loading: true,
    fecha_inscripcion: "",
    descuento: "",
    tipo_descuento: "",
    talleresOption: [],
    formato: "",
  });

  const [validate, setValidation] = useState({
    alumnoValidate: "gray",
    disciplinaValidate: "gray",
    tallerValidate: "gray",
  });

  const { showNotification } = useContext(NotificacionContext);
  // eslint-disable-next-line
  const { GlobalState, setGlobalState } = useContext(GlobalContext);

  const openmodal = () => {
    setState({ ...state, open: true });
  };

  const [loader, setLoader] = useState(false);

  const handleClose = () => {
    setState({
      ...state,
      open: false,
      openCaptura: false,
      alumnoSelect: null,
      TallerSelect: null,
      disciplinaSelect: "",
      errorAlumnoS: false,
      errorTallerS: false,
      idTaller: "",
      openDetalle: false,
    });
  };

  const openmodalDetalle = (talleres) => {
    setState({
      ...state,
      openDetalle: true,
      talleresAlumnosInscritos: state.alumnosInscritos[talleres].talleres,
      alumnoActual: state.alumnosInscritos[talleres].alumno,
    });
  };

  const verDetalleFormato = async (i) => {
    const formatoDetalle = await services("GET", "usuarios/miscursos", null);

    if (formatoDetalle.status === 200) {
      const data = formatoDetalle.data[i].talleres[0].FORMATO;
      setState({
        ...state,
        openCaptura: true,
      });
      setGlobalState({
        ...GlobalState,
        formatoDetalle: formatoDetalle.data[i].talleres[0].FORMATO,
      });
      // console.log(data, "data");
    }
  };

  const handleCloseDetalle = () => {
    setState({ ...state, openDetalle: false });
  };

  const cancelFunction = async (dataIndex) => {
    let dataCancel = await services(
      "POST",
      `usuarios/cancelarcurso/${dataIndex}`,
      null
    );
    if (dataCancel.status === 200) {
      if (dataCancel.data?.error) {
        showNotification("warning", "Alerta!", dataCancel.data.error, null, 4);
      } else {
        showNotification(
          "success",
          "Acción Exitosa!",
          "La inscripción se canceló correctamente.",
          null,
          4
        );

        api();
      }
    } else {
      showNotification("error", "Algo salio mal", dataCancel.error, null, 4);
    }
  };

  const columns = [
    {
      name: "alumno",
      label: "Alumno",
      align: "center",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex, i) => {
          let alumno = state.alumnosInscritos[dataIndex].alumno;
          return (
            <div
              style={{ height: "60px", width: "100%" }}
              className={
                i % 2 === 0
                  ? "bg-rosaClaro d-flex align-items-center justify-content-center"
                  : "d-flex align-items-center justify-content-center"
              }
            >
              {alumno}
            </div>
          );
        },
      },
    },
    {
      name: "fechainicioperiodo",
      label: "Inicio periodo",
      align: "center",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex, i) => {
          let fechainicioperiodo =
            state.alumnosInscritos[dataIndex].fechainicioperiodo;
          return (
            <div
              style={{ height: "60px", width: "100%" }}
              className={
                i % 2 === 0
                  ? "bg-rosaClaro d-flex align-items-center justify-content-center"
                  : "d-flex align-items-center justify-content-center"
              }
            >
              {fechainicioperiodo}
            </div>
          );
        },
      },
    },
    {
      name: "fechafinperiodo",
      label: "Fin periodo",
      align: "center",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex, i) => {
          let fechafinperiodo =
            state.alumnosInscritos[dataIndex].fechafinperiodo;
          return (
            <div
              style={{ height: "60px", width: "100%" }}
              className={
                i % 2 === 0
                  ? "bg-rosaClaro d-flex align-items-center justify-content-center"
                  : "d-flex align-items-center justify-content-center"
              }
            >
              {fechafinperiodo}
            </div>
          );
        },
      },
    },
    {
      name: "acciones",
      label: "Acciones",

      options: {
        filter: false,
        sort: false,
        download: false,
        customBodyRenderLite: (dataIndex, i) => {
          let alumno = state.alumnosInscritos[dataIndex].talleres;

          return (
            <div
              style={{ height: "60px", width: "100%" }}
              className={
                i % 2 === 0
                  ? "bg-rosaClaro d-flex align-items-center justify-content-center gap-1"
                  : "d-flex align-items-center justify-content-center gap-1"
              }
            >
              <Tooltip title="Ver Detalles">
                <div
                  style={{ height: 30, width: 30, zIndex: 0 }}
                  className="cta cta--primary cta--icon icon_btn"
                  onClick={() => {
                    openmodalDetalle(dataIndex);
                  }}
                >
                  <IconUI size={"20px"} color={"black"}>
                    <RemoveRedEyeIcon />
                  </IconUI>
                </div>
              </Tooltip>
              <Tooltip title="Ver documento">
                <div
                  style={{
                    height: 30,
                    width: 30,
                    zIndex: 0,
                    pointerEvents: alumno.length === 0 ? "none" : "auto",
                  }}
                  className={`${
                    alumno.length === 0 ? "opacity-25" : "opacity-100"
                  } cta cta--primary cta--icon icon_btn`}
                  onClick={() => {
                    if (alumno.length !== 0) {
                      verDetalleFormato(i);
                    }
                  }}
                >
                  <IconUI size={"20px"} color={"black"}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="icon icon-tabler icons-tabler-outline icon-tabler-file-text"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                      <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                      <path d="M9 9l1 0" />
                      <path d="M9 13l6 0" />
                      <path d="M9 17l6 0" />
                    </svg>
                  </IconUI>
                </div>
              </Tooltip>
            </div>
          );
        },
      },
    },
  ];

  const handleChangeAlumno = (e) => {
    setState({ ...state, alumnoSelect: e });
    setValidation({ ...validate, alumnoValidate: "gray" });
  };

  const handleChangeDisciplina = (e) => {
    setState({
      ...state,
      disciplinaSelect: e,
      TallerSelect: e.talleres,
      talleresOption: e.talleres,
    });
    setValidation({ ...validate, disciplinaValidate: "gray" });
  };

  useEffect(() => {}, [state.disciplinaSelect]);

  const handleChangeTallerSelect = (e) => {
    setState({ ...state, TallerSelect: e, idTaller: e.id });
    setValidation({ ...validate, tallerValidate: "gray" });
  };

  const handleChangeDescuento = (e) => {
    setState({ ...state, descuento: e.value });
  };

  const handleChangeTD = (e) => {
    setState({ ...state, tipo_descuento: e.value });
  };

  const api = async () => {
    const talleres = await services(
      "GET",
      "usuarios/listarperiodostallers",
      null
    );
    const alumnos = await services("POST", "usuarios/listarusuarios", {
      tipo: "Alumno",
    });

    const alumnosInscritos = await services("GET", "usuarios/miscursos", null);

    setState({
      ...state,
      talleres: talleres.data,
      alumnos: alumnos.data,
      loadingAlumnos: false,
      loadingTalleres: false,
      alumnosInscritos: alumnosInscritos.data,
      loading: false,
      open: false,
      openDetalle: false,
      fecha_inscripcion: "",
    });
  };

  const inscribirAlumno = async () => {
    setLoader(true);
    if (!state.alumnoSelect || !state.disciplinaSelect || !state.idTaller) {
      showNotification(
        "warning",
        "Campos requeridos!",
        "Por favor, llena todos los campos requeridos",
        null,
        4
      );

      let alumnoValidate = "";
      let disciplinaValidate = "";
      let tallerValidate = "";

      if (!state.alumnoSelect) {
        alumnoValidate = "red";
      }

      if (!state.disciplinaSelect) {
        disciplinaValidate = "red";
      }

      if (!state.idTaller) {
        tallerValidate = "red";
      }

      setValidation({
        ...validate,
        alumnoValidate: alumnoValidate,
        disciplinaValidate: disciplinaValidate,
        tallerValidate: tallerValidate,
      });
    } else {
      const alumnoID = state.alumnoSelect.id;
      const tallerID = state.idTaller;
      const descuento = state.descuento;
      if (alumnoID && tallerID) {
        const dataIscripcion = await services(
          "GET",
          `usuarios/crearinscripcionalumno?tipo=${state.tipo_descuento}&alumno=${alumnoID}&taller=${tallerID}&descuento=${descuento}`,
          null
        );
        if (dataIscripcion.status === 200) {
          if (dataIscripcion.data?.error) {
            showNotification(
              "warning",
              "Alerta!",
              dataIscripcion.data.error,
              null,
              4
            );
          } else {
            showNotification(
              "success",
              "Acción Exitosa!",
              "El alumno se inscribió correctamente.",
              null,
              4
            );

            const apiUpdateInscriptions = async () => {
              let alumnosInscritosUpdated = await services(
                "GET",
                "usuarios/miscursos",
                null
              );
              setState({
                ...state,
                alumnosInscritos: alumnosInscritosUpdated.data,
                open: false,
              });
            };
            apiUpdateInscriptions();
          }
        } else {
          showNotification(
            "error",
            "Algo salio mal",
            dataIscripcion.error,
            null,
            4
          );
        }
      } else {
        showNotification(
          "error",
          "Algo salio mal",
          "No se pudo acceder a la Base de datos, consulta al administrador.",
          null,
          4
        );
      }
    }
    setLoader(false);
  };

  const descuento = [
    { descripcion: "Descuento 0%", value: 0 },
    { descripcion: "Descuento 25%", value: 25 },
    { descripcion: "Descuento 50%", value: 50 },
    { descripcion: "Descuento 75%", value: 75 },
    { descripcion: "Descuento 100%", value: 100 },
  ];

  const optionsTD = [
    { value: "STASPE", label: "STASPE" },
    { value: "INAPAM", label: "INAPAM" },
    { value: "bajos_recursos", label: "Bajos Recursos" },
    { value: "Discapacidad", label: "Discapacidad" },
    { value: "Ordinario", label: "Ordinario" },
  ];

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      api();
    }

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {loader && <LoaderComponent />}
      {state.loading ? (
        <div style={{ height: "calc(100vh - 80px)" }}>
          <span className="loader"></span>
        </div>
      ) : (
        <>
          {state.data ? (
            <div style={state.data.length > 3 ? {} : { height: "calc(100vh)" }}>
              <div className="container mb-5 ">
                <Grid sx={{ marginTop: 6 }} container spacing={2}>
                  <Grid align="center" item xs={12} sm={6} md={6}></Grid>
                  <Grid align="right" item xs={12} sm={6} md={6}>
                    <div
                      style={{ width: 300 }}
                      className="cta cta--guinda"
                      onClick={() => {
                        openmodal();
                      }}
                    >
                      <div className="me-2">
                        <IconUI size={"20px"} color={"white"}>
                          <AiOutlineUserAdd />
                        </IconUI>
                      </div>
                      Inscribir Nuevo Alumno
                    </div>
                  </Grid>
                </Grid>
              </div>
              <DataTable
                data={state.alumnosInscritos}
                title={"Inscripciones"}
                columns={columns}
                style={{ marginBottom: "5%" }}
                more={{
                  searchPlaceholder: "Buscar por Alumno",
                }}
              />

              <Modal
                open={state.open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={styleModal}>
                  <Grid item xs={12} sm={12} md={12}>
                    <h4>Inscribir alumno</h4>
                    <hr></hr>
                  </Grid>
                  <Grid
                    container
                    spacing={3}
                    sx={{ justifyContent: "center", alignItems: "center" }}
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <Select
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderColor: state.isFocused
                              ? "grey"
                              : validate.alumnoValidate,
                          }),
                        }}
                        getOptionValue={(options) => options.id}
                        getOptionLabel={(options) =>
                          `${options.nombre} ${options.apaterno} ${options.amaterno}`
                        }
                        className="Alumno"
                        placeholder={
                          <>
                            <SearchIcon />
                            Alumnos
                          </>
                        }
                        classNamePrefix="Seleccione Alumno"
                        isLoading={state.loadingAlumnos}
                        isClearable={true}
                        isSearchable={true}
                        name="Alumno"
                        options={state.alumnos}
                        onChange={(item) => handleChangeAlumno(item)}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <Select
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderColor: state.isFocused
                              ? "grey"
                              : validate.disciplinaValidate,
                          }),
                        }}
                        getOptionValue={(options) => options.disciplina}
                        getOptionLabel={(options) => `${options.disciplina}`}
                        className="Taller"
                        placeholder="Disciplina"
                        classNamePrefix="Seleccione Taller"
                        isLoading={state.loadingTalleres}
                        isClearable={true}
                        isSearchable={true}
                        name="Disciplina"
                        options={state.talleres}
                        onChange={(item) => handleChangeDisciplina(item)}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <Select
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderColor: state.isFocused
                              ? "grey"
                              : validate.tallerValidate,
                          }),
                        }}
                        getOptionValue={(options) => options.id}
                        getOptionLabel={(options) => `${options.nombretaller}`}
                        className="Taller"
                        placeholder="Talleres"
                        classNamePrefix="Seleccione Taller"
                        isLoading={state.loadingTalleres}
                        isClearable={true}
                        isSearchable={true}
                        name="Alumno"
                        options={state.TallerSelect}
                        onChange={(item) => handleChangeTallerSelect(item)}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <Select
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderColor: state.isFocused
                              ? "grey"
                              : validate.alumnoValidate,
                          }),
                        }}
                        getOptionValue={(options) => options.value}
                        getOptionLabel={(options) => `${options.descripcion}`}
                        className="descuento"
                        placeholder="Descuento"
                        classNamePrefix="Seleccione Descuento"
                        isClearable={true}
                        isSearchable={true}
                        name="descuento"
                        options={descuento}
                        onChange={(item) => handleChangeDescuento(item)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <Select
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderColor: state.isFocused
                              ? "grey"
                              : validate.alumnoValidate,
                          }),
                        }}
                        getOptionValue={(options) => options.value}
                        getOptionLabel={(options) => `${options.label}`}
                        className="descuento"
                        placeholder="Tipo de Descuento"
                        classNamePrefix="Seleccione Tipo de Descuento"
                        isClearable={true}
                        isSearchable={true}
                        name="descuento"
                        options={optionsTD}
                        onChange={(item) => handleChangeTD(item)}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={2}
                    sx={{ justifyContent: "center", alignItems: "center" }}
                  ></Grid>
                  <Grid
                    container
                    className="mt-5"
                    spacing={2}
                    sx={{
                      flexDirection: "column",
                      textAlign: "center",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {state.talleresOption?.length !== 0 && (
                      <>
                        Fechas de Inscripciones
                        {state.talleresOption.map((elem) => {
                          return (
                            <p>
                              {" "}
                              <strong>{elem.nombretaller}</strong> <br /> Fecha
                              Inicio:{elem.fechaincrpcioninicio} - Fecha Final{" "}
                              {elem.fechaincrpcionfin}
                            </p>
                          );
                        })}
                      </>
                    )}
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      justifyContent: "center",
                      alignItems: "center",
                      paddingTop: "5%",
                    }}
                  >
                    <Grid item xs="auto">
                      <ColorButton
                        variant="outlined"
                        type="button"
                        endIcon={<CloseIcon />}
                        onClick={handleClose}
                      >
                        Cancelar
                      </ColorButton>
                    </Grid>
                    <Grid item xs="auto">
                      <ColorButton
                        variant="outlined"
                        type="button"
                        endIcon={<SaveIcon />}
                        onClick={() => inscribirAlumno()}
                      >
                        Inscribir
                      </ColorButton>
                    </Grid>
                  </Grid>
                </Box>
              </Modal>

              <Modal
                open={state.openDetalle}
                onClose={handleCloseDetalle}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={styleModal}>
                  <div className="mb-3">
                    {state.talleresAlumnosInscritos.length !== 0 ? (
                      <>
                        <h2>{state.alumnoActual}</h2>
                        <hr></hr>
                        {state.talleresAlumnosInscritos.map((item) => {
                          return (
                            <>
                              <Accordion>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="detalle"
                                  id="detalle"
                                  style={{
                                    backgroundColor: "#69164a",
                                    color: "white",
                                  }}
                                >
                                  <Typography>
                                    Taller: {item.nombretaller}
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <table className="table table-bordered table-hover table-checkable ">
                                    <tbody>
                                      <tr className="bg-rosaClaro">
                                        <td className="text-left">
                                          <b>Fecha límite de pago</b>:
                                        </td>
                                        <td className="text-center break">
                                          <b>{item.fechavencimientotaller}</b>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="text-left">
                                          <b>Status pago:</b>
                                        </td>
                                        <td className="text-center break">
                                          <b>{item.stastustaller}</b>
                                        </td>
                                      </tr>
                                      <tr className="bg-rosaClaro">
                                        <td className="text-left">
                                          <b>Línea de captura:</b>
                                        </td>
                                        <td className="text-center break">
                                          <b>{item.lineacaptura}</b>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="text-left">
                                          <b>Nivel:</b>
                                        </td>
                                        <td className="text-center break">
                                          <b>{item.nivel}</b>
                                        </td>
                                      </tr>
                                      <tr className="bg-rosaClaro">
                                        <td className="text-left">
                                          <b>Descuento:</b>
                                        </td>
                                        <td className="text-center break">
                                          <b>{item.descuento} %</b>
                                        </td>
                                      </tr>
                                      {item.stastustaller === "pendiente" ? (
                                        <tr className="bg-rosaClaro">
                                          <td className="text-left">
                                            <b>Acciones:</b>
                                          </td>
                                          <td className="text-center break">
                                            <ColorButton
                                              variant="outlined"
                                              type="button"
                                              endIcon={<FcCancel />}
                                              onClick={() => {
                                                showNotification(
                                                  "option",
                                                  "Estás seguro de realizar esta acción?",
                                                  "Al dar click en Continuar se cancelará la inscripción seleccionada",
                                                  () => {
                                                    if (item.id) {
                                                      cancelFunction(item.id);
                                                    } else {
                                                      showNotification(
                                                        "error",
                                                        "Algo salio mal",
                                                        "No se pudo acceder a la Base de datos, consulta al administrador.",
                                                        null,
                                                        4
                                                      );
                                                    }
                                                  },
                                                  null
                                                );
                                              }}
                                            >
                                              Cancelar Inscripción
                                            </ColorButton>
                                          </td>
                                        </tr>
                                      ) : (
                                        <></>
                                      )}
                                    </tbody>
                                  </table>
                                </AccordionDetails>
                              </Accordion>
                            </>
                          );
                        })}
                      </>
                    ) : (
                      <>
                        <hr></hr>
                        <h3 className="text-center">
                          Este alumno no cuenta con talleres activos...
                        </h3>
                        <hr></hr>
                      </>
                    )}
                  </div>

                  <Grid container spacing={2} sx={{ paddingTop: "5%" }}>
                    <div className="d-flex w-100">
                      <div className="col"></div>
                      <ColorButton
                        variant="outlined"
                        type="button"
                        endIcon={<CloseIcon />}
                        onClick={handleCloseDetalle}
                      >
                        Cerrar
                      </ColorButton>
                    </div>
                  </Grid>
                </Box>
              </Modal>

              <Modal
                open={state.openCaptura}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Grid>
                    <div className=" position-relative vh-100 d-flex flex-direction-column justify-content-center aling-items-center pt-3 pb-3">
                      <h4 className="position-absolute top-0 start-0 font-bold">
                        Orden de pago
                      </h4>
                      <object
                        className="pt-4"
                        data={GlobalState.formatoDetalle}
                        type="application/pdf"
                        height="92%"
                        width="100%"
                      ></object>
                    </div>
                  </Grid>
                </Box>
              </Modal>
            </div>
          ) : (
            <div style={{ height: "calc(100vh - 80px)" }}>
              <span class="loader"></span>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Inscripciones;
